// uppercase function that ignores 'α' ( alpha )

import { LOCALES_FORMAT } from '../data/localeFormat';

import formater from '../data-transformer/formatMoney';
import { trim } from 'lodash-es';
// same as deprecated function upperCaseModel()
export function upperCaseIgnoreSpecialChars(
  stringToUppercase: string,
  ignoreChars = ['α']
) {
  return stringToUppercase
    .split('')
    .map((char) => {
      if (ignoreChars.includes(char)) {
        return char;
      }
      return char.toUpperCase();
    })
    .join('');
}

export function displayMeasuringUnits(title: string, content: string) {
  let measuringUnit = '';
  if (content != '') {
    switch (title.toLowerCase()) {
      case 'machine weight':
      case 'max weight':
      case 'workpiece weight':
      case 'max tool weight':
      case 'max payload':
      case 'range payload':
        if (content != '' && content.toLowerCase().indexOf('kg') == -1) {
          return (measuringUnit = 'KG');
        }
        break;
      case 'clamping force':
      case 'pressing force':
      case 'punching force':
      case 'shearing force':
      case 'stamping force':
        if (content != '' && content.toLowerCase().indexOf('kn') == -1) {
          return (measuringUnit = 'KN');
        }
        break;
      case 'injection pressure':
        if (content != '' && content.toLowerCase().indexOf('bar') == -1) {
          return (measuringUnit = 'BAR');
        }
        break;
      case 'laser power':
        if (content != '' && content.toLowerCase().indexOf('kw') == -1) {
          return (measuringUnit = 'KW');
        }
        break;
      case 'spindle speed range':
        if (content != '' && content.toLowerCase().indexOf('1/min') == -1) {
          return (measuringUnit = '1/MIN');
        }
        break;
      case 'stroke volume':
        if (content != '' && content.toLowerCase().indexOf('cm³') == -1) {
          return (measuringUnit = 'CM³');
        }
        break;
      case 'measurements width':
      case 'measurements depth':
      case 'measurements height':
      case 'bending length':
      case 'ejector stroke':
      case 'workpiece drm':
      case 'workpiece x':
      case 'workpiece y':
      case 'workpiece z':
      case 'maximum sheet thickness':
      case 'outer width':
      case 'outer length':
      case 'aluminium':
      case 'steel':
      case 'stainless steel':
      case 'max tool diameter':
      case 'max tool length':
      case 'x-axis movement':
      case 'y-axis movement':
      case 'z-axis movement':
      case 'a-axis movement':
      case 'b-axis movement':
      case 'c-axis movement':
      case 'u-axis movement':
      case 'v-axis movement':
      case 'w-axis movement':
      case 'x-y-plane movement':
      case 'x-y-plane(diagonal_1) movement':
      case 'x-y-plane(diagonal_2) movement':
      case 'u/v movement':
      case 'pa movement':
      case 'ps movement':
      case 'a_(working_length) movement':
      case 'b_(distance_between_uprights) movement':
      case 'c_(stroke) movement':
      case 'd_(gap) movement':
      case 'e_(distance_table/ram) movement':
      case 'f_(table_width) movement':
      case 'cross_slide_2 movement':
      case 'compound_slide_1 movement':
      case 'compound_slide_2 movement':
      case 'turret movement':
      case 'i-axis movement':
      case 'r-axis movement':
      case 'backgauge-x movement':
      case 'backgauge-x1 movement':
      case 'backgauge-x2 movement':
      case 'backgauge-z movement':
      case 'backgauge-z1 movement':
      case 'backgauge-z2 movement':
      case 'backgauge-r1 movement':
      case 'backgauge-r2 movement':
      case 'x-y-simultaneous movement':
      case 'cross_slide_1 movement':
      case 'backgauge-r movement':
      case 'max reach':
        if (content != '' && content.toLowerCase().indexOf('mm') == -1) {
          return (measuringUnit = 'MM');
        }
        break;
      case 'ambient temperature':
        if (content != '' && content.toLowerCase().indexOf('°C') == -1) {
          return (measuringUnit = '°C');
        }
        break;
      case '1-axis movement':
      case '2-axis movement':
      case '3-axis movement':
      case '4-axis movement':
      case '5-axis movement':
      case '6-axis movement':
        if (content != '' && content.toLowerCase().indexOf('°') == -1) {
          return (measuringUnit = '°');
        }
        break;
      case 'power consumption':
        break;
      default:
        if (content != '') {
          return (measuringUnit = '');
        }
    }
    return measuringUnit;
  }
}
/**
 * @param {string} telephone
 * @returns {string} telephoneLink
 */
export function telephoneLink(telephone: string) {
  return 'tel:' + (telephone ?? '').replace(/ /g, '');
}

/**
 * @param {string} telephone
 * @returns {string} does the telephone have a country prefix or not, usefull to hide the prefix input
 */
export function hasPhonePrefix(phone: string | undefined) {
  return (
    (phone && phone?.startsWith('+')) ||
    phone?.startsWith('0') ||
    phone?.startsWith('(') ||
    (phone?.[2] === ' ' &&
      phone.length > 8 &&
      trim(phone).split(' ').length == 2)
  );
}

export function getLocaleFormat(locale: string) {
  return locale && locale in LOCALES_FORMAT
    ? LOCALES_FORMAT?.[locale as keyof typeof LOCALES_FORMAT]
    : LOCALES_FORMAT['en-US'];
}

export function currencyFormat(
  number: number,
  locale?: string,
  withoutSign?: boolean
) {
  const format = getLocaleFormat(locale ?? getLocale());
  const sign = withoutSign ? '%v' : format.signFormat;
  return formater.formatMoney(
    number,
    format.currency,
    0,
    format.thousand,
    format.decimal,
    sign
  );
  // return new Intl.NumberFormat(getLocale(), {
  //   style: 'currency',
  //   currency: currency_ISO,
  //   maximumFractionDigits: 0,
  // }).format(number);
}

export function numberFormat(number: number) {
  // get the locale from nextjs

  return new Intl.NumberFormat(getLocale(), {
    maximumFractionDigits: 0,
  }).format(number);
}

function getLocale() {
  const defaultLocale = 'de-DE';
  if (typeof window == 'undefined') return defaultLocale;
  return window?.__NEXT_DATA__?.props?.lang ?? defaultLocale;
}
// @deprecated version of accentsTidy()
export function removeAccents(s: string) {
  s = s.toLowerCase();
  s = s.replace(new RegExp('[àáâãäå]', 'g'), 'a');
  s = s.replace(new RegExp('æ', 'g'), 'ae');
  s = s.replace(new RegExp('ç', 'g'), 'c');
  s = s.replace(new RegExp('[èéêëę]', 'g'), 'e');
  s = s.replace(new RegExp('[ìíîï]', 'g'), 'i');
  s = s.replace(new RegExp('ñ', 'g'), 'n');
  s = s.replace(new RegExp('[òóôõö]', 'g'), 'o');
  s = s.replace(new RegExp('œ', 'g'), 'oe');
  s = s.replace(new RegExp('[ùúûü]', 'g'), 'u');
  s = s.replace(new RegExp('[ýÿ]', 'g'), 'y');
  s = s.replace(new RegExp('š', 'g'), 's');
  s = s.replace(new RegExp('ż', 'g'), 'z');
  return s;
}

export function slugify(s: string) {
  if (!s) {
    console.warn('slugify() called with empty string');
    return '';
  }
  return removeAccents(
    s
      .replace(/ /g, '-')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase()
  );
}

// a function to remove all parentesis
export function removeParenthesis(s: string) {
  return s.replace('(', '').replace(')', '');
}

export function getCategoryUrl(category: string) {
  console.log(category);
  if (category == 'Wood Working') {
    return '/products/used-wood-working-machinery';
  }
  return `/products/used-${slugify(category)}`;
}

export function getNewsRoomLink(locale = 'en-US') {
  return (
    'https://newsroom.gindumac.com/' +
    (isLocaleGerman(locale)
      ? 'de'
      : (locale ?? 'en-US').split('-')[0] == 'es'
      ? 'es'
      : '')
  );
}

export function isLocaleGerman(locale = 'en-US') {
  return ['de-DE', 'de-AT'].includes(locale ?? 'en-US');
}
