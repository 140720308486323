import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ModalsSliceState {
  isNewsletterModalOpen: boolean;
  isGindumacPriceModalOpen: boolean;
  isRentingModalOpen: boolean;
  isGetQuoteModalOpen: boolean;
  isSignUpModalOpen: boolean;
  isReferalModalOpen: boolean;
}

export const initialState: ModalsSliceState = {
  isNewsletterModalOpen: false,
  isGindumacPriceModalOpen: false,
  isRentingModalOpen: false,
  isGetQuoteModalOpen: false,
  isSignUpModalOpen: false,
  isReferalModalOpen: false,
};

export const modalsSlice = createSlice({
  name: 'modalsSlice',
  initialState,
  reducers: {
    setIsNewsletterModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isNewsletterModalOpen = action.payload;
    },
    setIsGindumacPriceModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isGindumacPriceModalOpen = action.payload;
    },
    setIsRentingModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isRentingModalOpen = action.payload;
    },
    setIsGetQuoteModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isGetQuoteModalOpen = action.payload;
    },
    setIsSignUpModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isSignUpModalOpen = action.payload;
    },
    setIsReferalModalOpen: (state, action: PayloadAction<boolean>) => {
      state.isReferalModalOpen = action.payload;
    },
  },
});

export const {
  setIsNewsletterModalOpen,
  setIsGindumacPriceModalOpen,
  setIsRentingModalOpen,
  setIsGetQuoteModalOpen,
  setIsSignUpModalOpen,
  setIsReferalModalOpen,
} = modalsSlice.actions;

export default modalsSlice.reducer;
