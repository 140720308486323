import * as React from 'react';
import { useState } from 'react';

import useTranslation from 'next-translate/useTranslation';
import NavbarDrawer from '../ui/NavbarDrawer';
import { telephoneLink } from '@/lib/data-transformer/gindumacUtils';
import { gtagContactEvent } from '@/lib/utilities/analytics/gtm';

export function ContactNavbarSlider({
  contactEmail,
}: {
  contactEmail: string;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation('products');

  return (
    <>
      <a href='#' onClick={() => setIsOpen(true)}>
        {t('products:HEADER_SUPPORT')}
      </a>
      <NavbarDrawer isOpen={isOpen} setIsOpen={setIsOpen}>
        <ul className='my-6 flex flex-col items-center justify-center space-y-4'>
          <li>
            <h5 className='text-center font-secondary text-2xl font-extralight text-brand-500'>
              {t('products:HEADER_SUPPORT_CONTACT_WITH_US_HEADER')}
            </h5>
            <p className='text-center text-muted-500'>
              {t('products:HEADER_SUPPORT_CONTACT_WITH_US_SUBHEADER')}
            </p>
          </li>
          <div className='mx-auto grid max-w-3xl grid-cols-2 gap-4'>
            <li className='flex flex-col items-center justify-center bg-[#f6f6f6] p-4 hover:bg-[#E6E6E6]'>
              <a href={telephoneLink(t('products:HEADER_PHONE'))}>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={`/svg/icons-08.svg`}
                  alt={t('products:HEADER_PHONE')}
                  className='h-28'
                />
                <span>{t('products:HEADER_PHONE')}</span>
              </a>
            </li>
            <li className='flex flex-col items-center justify-center bg-[#f6f6f6] p-4 hover:bg-[#E6E6E6]'>
              <a onClick={gtagContactEvent} href={'mailto:' + contactEmail}>
                {/* eslint-disable-next-line @next/next/no-img-element */}
                <img
                  src={`/svg/icons-19.svg`}
                  alt={contactEmail}
                  className='h-28'
                />
                <span>{contactEmail}</span>
              </a>
            </li>
          </div>
        </ul>
      </NavbarDrawer>
    </>
  );
}
