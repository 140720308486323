import useTranslation from 'next-translate/useTranslation';
import styles from './success.module.css';

export function SuccessCheck({
  message,
  subtitle,
  subtitle2,
}: {
  message?: string;
  subtitle?: string;
  subtitle2?: string;
}) {
  const { t } = useTranslation('products');
  message = message ?? t('products:MESSAGE_SENT');
  return (
    <div className={styles.wrapper} data-testid='form-success-check'>
      <svg
        className={styles.checkmark}
        xmlns='http://www.w3.org/2000/svg'
        viewBox='0 0 52 52'
      >
        <circle
          className={styles.checkmark__circle}
          cx={26}
          cy={26}
          r={25}
          fill='none'
        />
        <path
          className={styles.checkmark__check}
          fill='none'
          d='M14.1 27.2l7.1 7.2 16.7-16.8'
        />
      </svg>
      <h1 className='text-center font-medium uppercase'>{message}</h1>
      {subtitle && (
        <h2
          className='mt-10 text-center'
          dangerouslySetInnerHTML={{
            __html: subtitle,
          }}
        />
      )}
      {subtitle2 && (
        <h2
          className='mt-10 text-center'
          dangerouslySetInnerHTML={{
            __html: subtitle2,
          }}
        />
      )}
    </div>
  );
}
