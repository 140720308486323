import axios from 'axios';
import { INameAndSlug, IProductAccountManager } from '@/lib/types/api';
import { getApiFromLocal } from '../data-transformer/getApiDomainFromLocale';
import { isProduction } from '../common';
import { DEFAULT_ACCOUNT_MANAGER } from '@/lib/repository/constants';

export const findAccountManagerByLocale = (
  accountManagers: IProductAccountManager[],
  locale?: string
): IProductAccountManager | null => {
  if (!locale) {
    return null;
  }
  return (
    accountManagers.find((manager) => manager.locales?.includes(locale)) || null
  );
};

export const getAccountManager = async (
  TLDlocale?: string,
  userLocale?: string
): Promise<IProductAccountManager> => {
  if (!TLDlocale) {
    throw new Error('Fetch account manager needs a locale');
  }
  const GLOBAL_ACCOUNT_MANAGER_LOCALE = 'en-GB';
  TLDlocale = TLDlocale.replace('en-US', GLOBAL_ACCOUNT_MANAGER_LOCALE);
  const accountManagers = await getCacheAccountManagers();
  return (
    findAccountManagerByLocale(accountManagers, userLocale) ??
    findAccountManagerByLocale(accountManagers, TLDlocale) ??
    findAccountManagerByLocale(
      accountManagers,
      GLOBAL_ACCOUNT_MANAGER_LOCALE
    ) ??
    DEFAULT_ACCOUNT_MANAGER
  );
};

export const getCacheAccountManagers = async (): Promise<
  IProductAccountManager[]
> => {
  const api = isProduction()
    ? 'https://www.gindumac.com/api/'
    : 'https://staging.gindumac.com/api/';
  const { data } = await axios.get<IProductAccountManager[]>(
    `${api}account-manager`
  );
  return data;
};

export const getAccountManagers = async (): Promise<
  IProductAccountManager[]
> => {
  const apiUrl = `${process.env.NEXT_PUBLIC_CRM_API_URL}account-manager/`;
  const { data } = await axios.get<IProductAccountManager[]>(apiUrl);
  if (data) {
    return data;
  }
  throw new Error('No account managers found');
};

export const getCategories = () => [
  {
    order_number: 1,
    name: 'Machine Tools',
    id: 'a0d9d006-465c-4044-9d24-ef0eb4470121',
    slug: 'machine-tool',
    href: '/products/used-machine-tools',
  },
  {
    order_number: 2,
    name: 'Sheet Metal Machinery',
    id: '8cfe086d-956f-4110-b3b4-0899d64d3b50',
    slug: 'sheet-metal',
    href: '/products/used-sheet-metal-machinery',
  },
  {
    order_number: 3,
    name: 'Plastics Processing Machinery',
    id: '21928eda-0aa4-45d9-b2d6-76ac85fcc4ec',
    slug: 'plastics-processing',
    href: '/products/used-plastics-processing-machinery',
  },
  {
    order_number: 4,
    name: 'Automation Equipment',
    id: '6236ba73-fe33-4c26-a243-65676f089698',
    slug: 'automation-equipment',
    href: '/products/used-automation-equipment',
  },
  {
    order_number: 5,
    name: 'Wood Working',
    id: '20d8339e-f71e-4651-9571-1978bb97860f',
    slug: 'wood-working',
    href: '/products/used-wood-working-machinery',
  },
];

export const getRelatedProductsBySKU = async (
  sku: string,
  locale: string
): Promise<any[]> => {
  const apiUrl = `${getApiFromLocal(
    locale
  )}/api/related-products/${encodeURIComponent(sku)}`;

  try {
    const { data } = await axios.get(apiUrl);
    if (data.status === 'success' && data.data.products.length) {
      return data.data.products;
    }
    return [];
  } catch (error) {
    console.error('Error fetching related products by SKU:', error);
    return [];
  }
};

interface ApiSuggestionsResponse {
  status: 'success' | 'error';
  data: {
    brands: INameAndSlug[];
    models: INameAndSlug[];
    application_types: INameAndSlug[];
  };
}

export const fetchSuggestions = async (
  search_text: string,
  locale: string
): Promise<ApiSuggestionsResponse> => {
  const apiUrl = `${getApiFromLocal(locale)}/api/v2/product/filter-suggester`;

  const { data } = await axios.post<ApiSuggestionsResponse>(
    apiUrl,
    { search_text },
    {
      headers: {
        Accept: 'application/json, text/plain, */*',
        'Content-Type': 'application/json',
      },
    }
  );
  return data;
};
