import { useAppSelector } from '@/redux/hooks';
import { AppState } from '@/redux/store';
import GoogleAnalytics from './GoogleAnalytics';
import FacebookAnalytics from './FacebookAnalytics';
import LinkedinAnalytics from './LinkedinAnalytics';
import BingAnalytics from './BingAnalytics';
import { SentryUserSetter } from './SentryUserSetter';

export function Tracking() {
  const hasAccepted = useAppSelector(
    (state: AppState) => state.cookieConsent.hasAccepted
  );
  return (
    <>
      <GoogleAnalytics />
      {process.env.NEXT_PUBLIC_ENABLE_TRACKING &&
      process.env.NEXT_PUBLIC_ENABLE_TRACKING != 'false' &&
      hasAccepted == 'yes' ? (
        <>
          <FacebookAnalytics />
          <LinkedinAnalytics />
          <BingAnalytics />
          <SentryUserSetter />
        </>
      ) : null}
    </>
  );
}
