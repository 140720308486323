import React from 'react';
import classNames from 'classnames';
import useTranslation from 'next-translate/useTranslation';
import { Path } from 'react-hook-form';
export type InputPros<FormTypes> = {
  name: Path<FormTypes>;
  register: any;
  errors: any;
  placeholder?: string;
  type?: 'text' | 'textarea' | 'checkbox' | 'email' | 'number' | 'password';
  validation?: {
    required?: boolean;
    maxLength?: number;
    minLength?: number;
    pattern?: { value: RegExp; message: string };
    min?: number;
    max?: number;
    setValueAs?: (value: string) => string;
  };
  required?: boolean;
  maxLength?: number;
  label?: string;
  isHorizontal?: boolean;
  labelClassNames?: string;
  containerClassNames?: string;
  defaultChecked?: boolean;
  value?: any;
  sufix?: string;
};
export function Input<FormTypes extends Record<string, unknown>>({
  register,
  errors,
  name,
  placeholder,
  type = 'text',
  validation = {},
  required,
  maxLength,
  label,
  isHorizontal = false,
  labelClassNames,
  containerClassNames,
  defaultChecked,
  value,
  sufix,
}: InputPros<FormTypes>) {
  const { t } = useTranslation('products');
  const classes = classNames('form-input text-[0.7rem] md:text-[1rem]', {
    'border-b-2 border-red-500': errors?.[name],
    'w-full': type != 'checkbox',
    'accent-brand-500 text-brand-500 h-6 w-5': type == 'checkbox',
  });
  if (required) {
    validation.required = true;
  }
  if (maxLength) {
    validation.maxLength = maxLength;
  }
  if (type == 'textarea') {
    return (
      <textarea
        className={classes + ' h-22 xl:32'}
        id={name}
        placeholder={placeholder}
        {...register(name, validation)}
      />
    );
  }
  if (type == 'email') {
    validation.pattern = {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: t('products:FORM_ERROR_EMAIL_INVALID'),
    };
  }
  isHorizontal = isHorizontal || type == 'checkbox';
  return (
    <div
      className={classNames(
        {
          'space-x-4': type == 'checkbox',
          'flex items-center': isHorizontal,
          'flex flex-col items-end': !isHorizontal,
          relative: sufix,
        },
        containerClassNames
      )}
    >
      <input
        type={type}
        className={classes}
        id={name}
        placeholder={placeholder}
        {...register(name, validation)}
        {...(type == 'checkbox' && defaultChecked
          ? { defaultChecked: true }
          : {})}
        {...(value ? { value } : {})}
      />
      {sufix && (
        <span className='absolute right-2 top-0 text-3xl text-gray-500 '>
          {sufix}
        </span>
      )}
      {/* label */}
      {label && (
        <label
          className={classNames('uppercase text-muted-500', labelClassNames)}
          htmlFor={name}
        >
          {label}
        </label>
      )}
      <ErrorMessage errors={errors} name={name} />
    </div>
  );
}

export function ErrorMessage({ errors, name }: { errors: any; name: string }) {
  const errorMessage = useErrorMessage(errors?.[name], name);
  return (
    <p className='text-sm text-red-500' data-testid={'error-message-' + name}>
      {errorMessage}
    </p>
  );
}

function useErrorMessage(
  error: { message: string; ref: any; type: string },
  name: string
) {
  const { t } = useTranslation();
  const requiredMessages = {
    company: t('products:FORM_ERROR_COMPANY_EMPTY'),
    'first-name': t('products:FORM_ERROR_FIRSTNAME_EMPTY'),
    firstname: t('products:FORM_ERROR_FIRSTNAME_EMPTY'),
    'last-name': t('products:FORM_ERROR_LASTNAME_EMPTY'),
    lastname: t('products:FORM_ERROR_LASTNAME_EMPTY'),
    email: t('products:FORM_ERROR_EMAIL_EMPTY'),
    'phone-prefix': t('products:FORM_ERROR_PHONE_PREFIX_EMPTY'),
    'phone-number': t('products:FORM_ERROR_PHONE_EMPTY'),
    phone: t('products:FORM_ERROR_PHONE_EMPTY'),
    subject: t('products:FORM_ERROR_SUBJECT_EMPTY'),
    message: t('products:FORM_ERROR_MESSAGE_EMPTY'),
    terms: t('products:FORM_ERROR_TERMSACCEPT'),
    // TODO: why this translation is in this file?
    country: t('seo:FORM_ERROR_COUNTRY_EMPTY'),
    city: t('products:FORM_ERROR_CITY_EMPTY'),
    under_power: t('products:FORM_ERROR_MACHINE_UNDER_POWER'),
    year: t('products:FORM_ERROR_YEAR_EMPTY'),
    model: t('products:FORM_ERROR_MODEL_EMPTY'),
    brand: t('products:FORM_ERROR_BRAND_EMPTY'),
    machine_category: t('products:FORM_ERROR_CATEGORY_EMPTY'),
    'price-range': t('products:FORM_ERROR_PRICE_EMPTY'),
    password: t('products:FORM_ERROR_PASSWORD_EMPTY'),
  };
  // remove 'acount-manager_' pr 'quote_' from the beginning of the name
  name = name.replace(
    /^(account-manager_|quote-|schedule-visit_|machine-details_|subscribe-|customer-details_|we-buy-your-project_|counter-offer_|search-request_)/,
    ''
  );
  if (!error?.type) return '';
  let errorMessage = '';
  if (error.type == 'required') {
    errorMessage = requiredMessages[name as keyof typeof requiredMessages];
    return errorMessage;
  }
  if (error.type == 'maxLength') {
    const tooBig = {
      year: t('products:FORM_ERROR_YEAR_NOTPAST'),
    };
    errorMessage = tooBig[name as keyof typeof tooBig];
    return errorMessage;
  }
  if (error.type == 'minLength') {
    const tooBig = {
      // TODO: i18n year min message
      year: t('products:FORM_ERROR_YEAR_EMPTY'),
      password: t('products:FORM_ERROR_PASSWORD_MIN'),
    };
    errorMessage = tooBig[name as keyof typeof tooBig];
    return errorMessage;
  }
  console.log('error', error);
  return errorMessage || error.message || 'Unknown error';
}
