import classNames from 'classnames';
import { Path } from 'react-hook-form';
import { ErrorMessage } from './Input';
import useTranslation from 'next-translate/useTranslation';
import { Link, useRouter } from 'next-translate-routes-multi-domain';
import { useUser } from '@auth0/nextjs-auth0/client';
// TODO: i18n , .com.tr is different, and in newsletter we have one for .com.tr one for .se and one for the rest
/*
 but also things like:
  schedule-visit.form.js
  ```
    if (gindumacConfig.tld != 'de' && gindumacConfig.tld != 'at') {
      terms_URL = `/${appUrls['TERMS_AND_CONDITIONS'].path}`;
    } else {
      terms_URL = 'https://gindumac-p2p.s3.amazonaws.com/corporate/Allgemeine-Verkaufsbedingungen_GINDUMAC-GmbH.pdf';
    }
  ```
*/

type InputTermsProps<FormTypes> = {
  name: Path<FormTypes>;
  errors: any;
  register: any;
  labelClassNames?: string;
};

export function InputTerms<FormTypes>({
  name,
  errors,
  register,
  labelClassNames,
}: InputTermsProps<FormTypes>) {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { user } = useUser();
  if (user) return null;
  // TODO: use Input type checkbox component
  return (
    <div>
      <input
        type='checkbox'
        id={name}
        {...register(name, {
          required: true,
        })}
      />
      {/* TODO: privacy policy link depends on lang */}
      <label htmlFor={name}>
        <span
          className={classNames('ml-2 text-sm text-muted-500', labelClassNames)}
        >
          {locale != 'tr-TR' ? (
            <>
              {t('products:FORM_PLACEHOLDER_TERMSACCEPT1')}{' '}
              {/* TODO: use next link */}
              <PrivacyLink />, <TermsAndConditionsOfPurchase />
              {' ' + t('products:TEXT_AND') + ' '}
              <TermsAndConditionsOfSales />
            </>
          ) : (
            <>
              <PrivacyLink /> , <TermsAndConditionsOfPurchase />
              {' ' + t('products:TEXT_AND') + ' '}
              <TermsAndConditionsOfSales />
              {t('products:FORM_PLACEHOLDER_TERMSACCEPT1')}
            </>
          )}
        </span>
      </label>
      <ErrorMessage errors={errors} name={name} />
    </div>
  );
}

function PrivacyLink() {
  const { t } = useTranslation();
  return (
    <Link href='/privacy'>
      <a target='_blank' className='uppercase underline'>
        {t('seo:FORM_PLACEHOLDER_PRIVACYACCEPT')}
      </a>
    </Link>
  );
}

function TermsAndConditionsOfSales() {
  const { t } = useTranslation();
  return (
    <Link href='/general-terms-and-conditions-of-purchase'>
      <a target='_blank' className='uppercase underline'>
        {t('seo:FOOTER_TERMS_AND_CONDITIONS_OF_SALE')}
      </a>
    </Link>
  );
}

function TermsAndConditionsOfPurchase() {
  const { t } = useTranslation();
  return (
    <Link href='/general-terms-and-conditions-of-sale'>
      <a target='_blank' className='uppercase underline'>
        {t('seo:FOOTER_TERMS_AND_CONDITIONS_OF_PURCHASE')}
      </a>
    </Link>
  );
}
