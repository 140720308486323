import Head from 'next/head';
import Script from 'next/script';

export default function BingAnalytics() {
  return (
    <>
      <Head>
        <noscript>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            height='0'
            width='0'
            alt=''
            src='//bat.bing.com/action/0?ti=5796167&Ver=2'
          />
        </noscript>
      </Head>
      {/* Bing Code */}
      <Script
        id='fb-script'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
          (function(w,d,t,r,u){
            var f,n,i;w[u]=w[u]||[],
            f=function(){
            var o={ti:"5796167"};o.q=w[u],w[u]=new UET(o),w[u].push("pageLoad")},n=d.createElement(t),n.src=r,n.async=1,n.onload=n.onreadystatechange=function(){
            var s=this.readyState;s&&s!=="loaded"&&s!=="complete"||(f(),n.onload=n.onreadystatechange=null)},i=d.getElementsByTagName(t)[0],i.parentNode.insertBefore(n,i)})(window,document,"script","//bat.bing.com/bat.js","uetq");
          `,
        }}
      />
    </>
  );
}
