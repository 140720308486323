import { Link } from 'next-translate-routes-multi-domain';
import { MdOutlineArrowDropDown } from 'react-icons/md';

export function Dropdown({
  title,
  links,
}: {
  title: string;
  links: { title: string; href: string }[];
}) {
  return (
    <div className='group relative inline-block'>
      <button className='inline-flex items-center rounded '>
        <span className='mr-1 uppercase'>{title}</span>
        <MdOutlineArrowDropDown className='h-4 w-4' />
      </button>
      <ul className='absolute hidden w-48 border bg-white pt-1 text-gray-700 group-hover:block'>
        {links.map((link) => (
          <li className='' key={title + link.title}>
            <Link href={link.href}>
              <a className='whitespace-no-wrap block bg-white px-8 py-4 hover:bg-brand-500 hover:text-white'>
                {link.title}
              </a>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}
