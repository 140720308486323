import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import { Fragment, ReactNode } from 'react';
type DrawerProps = {
  title?: string | ReactNode;
  description?: string;
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function NavbarDrawer({
  children,
  isOpen,
  setIsOpen,
}: DrawerProps) {
  return (
    <Transition show={isOpen} as={Fragment}>
      <Dialog
        unmount={false}
        onClose={() => setIsOpen(false)}
        className='fixed inset-0 z-30 w-full overflow-y-auto'
      >
        <div className={classNames('mt-20 flex h-auto w-full')}>
          <Transition.Child
            as={Fragment}
            enter='transition-opacity ease-in duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-30'
            entered='opacity-30'
            leave='transition-opacity ease-out duration-300'
            leaveFrom='opacity-30'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 z-40 bg-[#00000020]' />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter='transition ease-in-out duration-300 transform'
            enterFrom='-translate-y-full'
            enterTo='translate-y-0'
            leave='transition ease-in-out duration-300 transform'
            leaveFrom='translate-y-0'
            leaveTo='-translate-y-full opacity-0'
          >
            <div
              className={classNames(
                'z-50 flex w-full flex-col justify-between overflow-hidden bg-white text-left align-middle shadow-xl'
              )}
            >
              <div>
                <div>{children}</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
}
