import clsx from 'clsx';
import { Link } from 'next-translate-routes-multi-domain';

export function NavLink({
  label,
  href,
  color,
  classes,
}: {
  label: string;
  href: string;
  color?: Colors;
  classes?: string;
}) {
  return (
    <Link href={href}>
      <a className={getClassNameByColor(color) + ' ' + classes}>{label}</a>
    </Link>
  );
}

type Colors = 'brand' | 'secondary';
export function getClassNameByColor(color?: Colors) {
  return clsx(
    'flex items-center justify-center rounded border-[3px] px-4 py-1 transition duration-300 font-primary',
    {
      'border-brand-500 hover:bg-brand-500 hover:text-white': color === 'brand',
      'border-secondary-500 bg-secondary-500 hover:bg-white':
        color === 'secondary',
    }
  );
}
