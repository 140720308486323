import COUNTRY_NAMES from '@/lib/data/country-names.json';
import useTranslation from 'next-translate/useTranslation';
import { useEffect, useState } from 'react';
import { Path } from 'react-hook-form';
import { Select } from './Select';
import { capitalize, orderBy } from 'lodash-es';

export type SelectCountryProps<FormTypes> = {
  name: Path<FormTypes>;
  errors: any;
  register: any;
  value?: string;
  validation?: {
    required?: boolean;
    pattern?: { value: RegExp; message: string };
  };
  type?: 'country' | 'phoneCode';
  returnCountryEnglishName?: boolean;
};

export function SelectCountry<FormTypes extends Record<string, unknown>>({
  name,
  errors,
  register,
  value,
  validation,
  type = 'country',
  returnCountryEnglishName = false,
}: SelectCountryProps<FormTypes>) {
  const { t } = useTranslation('products');
  const countries = useCountries();
  validation = validation || {};
  validation.pattern = {
    value: /\S/,
    message: t('seo:FORM_ERROR_COUNTRY_EMPTY'),
  };
  const options = orderBy(
    countries.map((country) => ({
      value: returnCountryEnglishName
        ? country.enLabel
        : type == 'phoneCode'
        ? country.phoneCode
        : country.code,
      label:
        type == 'phoneCode'
          ? country.label.toUpperCase() +
            country.nativeLabel +
            '(+' +
            country.phoneCode +
            ')'
          : capitalize(country.label),
      key: country.code + country.phoneCode,
    })),
    'label',
    'asc'
  );
  return (
    <Select
      name={name}
      value={value}
      options={options}
      validation={{
        required: true,
      }}
      register={register}
      errors={errors}
      label={
        type == 'phoneCode'
          ? t('products:FORM_PLACEHOLDER_PHONE_PREFIX').toUpperCase()
          : t('products:FORM_PLACEHOLDER_COUNTRY_NAME').toUpperCase()
      }
    />
  );
}

function useCountries() {
  const { t } = useTranslation('products');
  const [countriesOptions, setCountriesOptions] = useState<
    {
      label: string;
      code: string;
      phoneCode: string;
      nativeLabel: string;
      enLabel: string;
    }[]
  >([]);

  useEffect(() => {
    const options = COUNTRY_NAMES.map((country) => ({
      code: country[0],
      label: t(country[1]),
      nativeLabel: country[2],
      phoneCode: country[3],
      enLabel: country[1],
    })).sort((a, b) => {
      const nameA = a.label.replace(/(\b\w)/gi, (m) => {
        return m.toUpperCase();
      });
      const nameB = b.label.replace(/(\b\w)/gi, (m) => {
        return m.toUpperCase();
      });
      return nameA.localeCompare(nameB);
    });
    setCountriesOptions(options);
  }, [t]);
  return countriesOptions;
}
