import useTranslation from 'next-translate/useTranslation';

export function BackToTop() {
  const { t } = useTranslation();
  return (
    <div
      className='flex cursor-pointer items-center justify-center bg-[#2b2b2b] p-5 hover:bg-[#3B3B3B]'
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }}
    >
      <div className=' text-white'>{t('products:FOOTER_BACK_TO_TOP')}</div>
    </div>
  );
}
