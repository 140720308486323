/* eslint-disable react/jsx-key */
// a error boundary component to catch errors in the app, display a "Error, reloading" message and reload the page after 5 seconds

import * as React from 'react';
import { ErrorBoundary } from '@sentry/react';
import { useRouter } from 'next/router';
import { Link } from 'next-translate-routes-multi-domain';
import { useEffect, useState } from 'react';
import Trans from 'next-translate/Trans';
import { setTag } from '@sentry/nextjs';
import { isDevelopment, isTesting } from '@/lib/common';
interface ReloadOnErrorProps {
  delay?: number;
  children: React.ReactNode;
}

const ReloadOnError: React.FC<ReloadOnErrorProps> = ({
  children,
  delay = 5, // default delay of 5 seconds
}) => {
  const router = useRouter();
  const [isCountDownEnable, setIsCountDownEnable] = useState(false);
  // a react state that will go from 5 to 1 every second
  const [count, setCount] = useState(delay);
  useEffect(() => {
    let interval: NodeJS.Timeout | undefined = undefined;
    if (isCountDownEnable) {
      interval = setInterval(() => {
        setCount((prevCount) => {
          if (prevCount > 0) {
            return prevCount - 1;
          } else {
            return 0;
          }
        });
      }, 1000);
    }
    return () => clearInterval(interval);
  }, [isCountDownEnable]);

  useEffect(() => {
    if (count === 0) {
      // set sentry tag for reloading
      setTag('error boundary', 'true');
      router.reload();
    }
  }, [count, router]);
  if (isDevelopment() || isTesting()) {
    return <>{children}</>;
  }
  return (
    <ErrorBoundary
      onError={() => {
        setIsCountDownEnable(true);
      }}
      fallback={
        <div className='flex h-screen flex-col items-center justify-center'>
          <div className='flex h-screen flex-col items-center justify-center'>
            <Trans
              i18nKey='products:APP_ERROR_MESSAGE'
              components={[
                <h1 className='text-2xl font-bold' />,
                <button
                  onClick={() => router.reload()}
                  className='rounded bg-brand-500 px-4 py-2 text-white'
                />,
                <ProductsLink />,
                <p className='mt-4 text-xl' />,
                <span className='font-bold' />,
              ]}
              values={{ count: count }}
            />
          </div>
        </div>
      }
      onReset={() => {
        setIsCountDownEnable(false);
      }}
    >
      {children}
    </ErrorBoundary>
  );
};

function ProductsLink({ children }: { children?: React.ReactNode }) {
  return (
    <Link href='/products'>
      <a className='text-blue-500 underline'> {children} </a>
    </Link>
  );
}

export default ReloadOnError;
