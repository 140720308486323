const DOMAINS = require('../data/domains');
const DOMAINS_ARRAY = DOMAINS.DOMAINS_ARRAY;
const {
  isProduction,
  isStaging,
  isTesting,
  isDevelopment,
} = require('../common');

function getDomains() {
  let domains = [];
  if (isDevelopment() || isTesting()) {
    domains = DOMAINS_ARRAY.filter((d) => !d.production).map((d) => ({
      defaultLocale: d.locale,
      http: true,
      domain: d.domain,
    }));
  } else if (isStaging()) {
    domains = DOMAINS_ARRAY.filter(
      (d) => d.production && d.domain.includes('staging.')
    ).map((d) => ({
      domain: d.domain,
      defaultLocale: d.locale,
    }));
  } else if (isProduction()) {
    domains = DOMAINS_ARRAY.filter(
      (d) => d.production && !d.domain.includes('staging.')
    ).map((d) => ({
      domain: d.domain,
      defaultLocale: d.locale,
    }));
  }
  return domains;
}

function getDomainsByEnv(env) {
  if (env === 'staging') {
    return DOMAINS_ARRAY.filter(
      (d) => d.production && d.domain.includes('staging.')
    );
  }
  if (env === 'production') {
    return DOMAINS_ARRAY.filter(
      (d) => d.production && !d.domain.includes('staging.')
    );
  }
  return DOMAINS_ARRAY.filter((d) => !d.production);
}

function getHost(locale) {
  return getDomains()?.find((domain) => domain.defaultLocale == locale);
}

function getDomainByLang(locale) {
  const langDomain = getHost(locale);
  const url =
    langDomain && 'http' in langDomain && langDomain?.['http'] === true
      ? 'http://' + langDomain['domain'] + ':3003'
      : 'https://' + langDomain?.['domain'];
  return url;
}

exports.getDomains = getDomains;
exports.getHost = getHost;
exports.getDomainByLang = getDomainByLang;
exports.getDomainsByEnv = getDomainsByEnv;
