import { Claims, Session } from '@auth0/nextjs-auth0';

// TODO: a user can be an admin if they have a role of admin
export function isAdmin(session?: Session | null | Claims) {
  if (!session) return false;
  if (typeof session === 'object' && 'user' in session) {
    return session.user.isAdmin;
  }
  // if is claims
  return !!session?.isAdmin;
}
