import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { Link } from 'next-translate-routes-multi-domain';
import useTranslation from 'next-translate/useTranslation';
import { useEffect } from 'react';
import { setHasAccepted } from './cookieConsentSlice';
import { AppState } from '@/redux/store';
import store from 'store2';
import { updateGTMConsent } from './GoogleAnalytics';
import { isBoolean, isString } from 'lodash-es';

export default function CookieConsent() {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const hasAccepted = useAppSelector(
    (state: AppState) => state.cookieConsent.hasAccepted
  );
  useEffect(() => {
    const cookieConsent = store.get('cookieConsent');
    if (isBoolean(cookieConsent) || isString(cookieConsent)) {
      const consent = cookieConsent === 'yes' || cookieConsent === true;
      dispatch(setHasAccepted(consent ? 'yes' : 'no'));
      updateGTMConsent(consent);
    }
  }, [dispatch]);

  const handleCookieConsent = (answer: boolean) => {
    console.log('cookie consent accepted');
    store.set('cookieConsent', answer);
    dispatch(setHasAccepted(answer ? 'yes' : 'no'));
    updateGTMConsent(answer);
  };
  return (
    <div className='sticky bottom-0 z-50 w-full bg-brand-800'>
      {hasAccepted == null &&
        process.env.NEXT_PUBLIC_ENABLE_TRACKING !== 'false' && (
          <div className='flex flex-col items-center p-4 text-white md:flex-row'>
            <p className='block lg:hidden'>
              {t('seo:COOKIES_QUOTE2')} &nbsp;
              {t('products:COOKIES_TEXT_CLICK')}{' '}
              <Link href='/cookie-policy' prefetch={false}>
                {t('products:COOKIES_TEXT_HERE')}
              </Link>{' '}
              {t('products:COOKIES_TEXT_KNOWMORE')}
            </p>
            <p className='hidden lg:block'>
              {t('products:COOKIES_QUOTE1')} &nbsp;
              {t('products:COOKIES_TEXT_CLICK')}{' '}
              <Link href='/cookie-policy' prefetch={false}>
                {t('products:COOKIES_TEXT_HERE')}
              </Link>{' '}
              {t('products:COOKIES_TEXT_KNOWMORE')}
            </p>
            <a
              id='gd-cookies-accept-button'
              className='ml-2 w-full cursor-pointer p-4 text-center uppercase md:w-auto'
              onClick={() => handleCookieConsent(false)}
            >
              {t('products:FORM_BTN_REJECT')}
            </a>
            <a
              id='gd-cookies-accept-button'
              className='ml-2 w-full cursor-pointer border-2 p-4 text-center uppercase md:w-auto '
              onClick={() => handleCookieConsent(true)}
            >
              {t('products:FORM_BTN_ACCEPT')}
            </a>
          </div>
        )}
    </div>
  );
}
