import { useIsMyGindumac } from '@/lib/hooks/useIsMyGindumac';
import { MyGindumacLogo } from '../mygindumac/MyGindumacLogo';
import clsx from 'clsx';

export function Logo({
  isWhite = false,
  hideMyGindumac,
}: {
  isWhite?: boolean;
  hideMyGindumac?: boolean;
}) {
  const isMyGindumacPage = useIsMyGindumac();
  return isMyGindumacPage ? (
    hideMyGindumac ? null : (
      <MyGindumacLogo />
    )
  ) : (
    <a
      href='/'
      className='mx-4 border-none outline-none ring-0 focus:border-transparent focus:outline-none focus:ring-0'
    >
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        src='/svg/logo/logo.svg'
        alt='Logo - GINDUMAC - Global Industry Machinery Cluster'
        title='Logo - GINDUMAC - Global Industry Machinery Cluster'
        className={clsx('h-10', { 'brightness-[5]': isWhite })}
      />
    </a>
  );
}
