import { Transition } from '@headlessui/react';
import { Fragment, useEffect, useState } from 'react';
import classNames from 'classnames';
import { useUser } from '@auth0/nextjs-auth0/client';
import { isAdmin } from '@/lib/utilities/isAdmin';
import useTranslation from 'next-translate/useTranslation';
import { Link, useRouter } from 'next-translate-routes-multi-domain';
import Drawer from '../ui/Modals/Drawer/Drawer';
import { UserIcon, ShoppingCartIcon, TagIcon } from '@heroicons/react/solid';
import { MobileNavLink } from '../layout/MobileNavbar';
import { MdLogout, MdSettings } from 'react-icons/md';
import { useWindowSize } from 'react-use';
import useUserMetadata from '@/lib/hooks/useUserData';
import { Logo } from '@/components/layout/Logo';
import { NavLink } from '../ui/Links/NavLink';

type SideBarOption = {
  label: string | React.ReactNode;
  url: string;
  container?: string;
};

export function SidebarContent({ options }: { options: SideBarOption[] }) {
  return (
    <ul className='no-print mb-4 mt-4 flex flex-col items-center space-y-4 bg-white duration-300 ease-in-out'>
      {options.map((o) => (
        <MobileNavLink
          title={o.label}
          href={o.url}
          key={o.url}
          container={o.container}
        />
      ))}
    </ul>
  );
}

function MySidebar({
  isOpen,
  setIsOpen,
  options,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  options: SideBarOption[];
}) {
  return (
    <Drawer isOpen={isOpen} setIsOpen={setIsOpen} size='xs'>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <div className='mt-4'>
          <div className='flex justify-center'>
            <Logo />
          </div>
          <SidebarContent options={options} />
        </div>
      </Transition>
    </Drawer>
  );
}

export function useUserDropdownOptions() {
  const [showAdminLinks, setShowAdminLinks] = useState(false);
  const { user } = useUser();
  const { metadata } = useUserMetadata();
  const { t } = useTranslation();
  const router = useRouter();
  const currentRoute = router.pathname;

  const isMyGindumacPage = ['/my/*'].some((route) => {
    const pattern = new RegExp(`^${route.replace('*', '.*')}$`);
    return pattern.test(currentRoute);
  });

  useEffect(() => {
    setShowAdminLinks(!!user && isAdmin(user));
  }, [user]);

  const options: SideBarOption[] = [];

  if (user) {
    options.push(
      metadata?.['circular-club']
        ? {
            label: (
              <div>
                <span className='font-moontime font-semibold'>{'my '}</span>
                <span>Referal</span>
              </div>
            ),
            url: '/my/referrals',
          }
        : {
            label: (
              <div>
                <span className='font-moontime font-semibold'>{'my '}</span>
                <span>Projects</span>
              </div>
            ),
            url: '/my/projects',
          },
      {
        label: (
          <div>
            <span className='font-moontime font-semibold'>{'my '}</span>
            <span>Dashboard</span>
          </div>
        ),
        url: '/my/dashboard',
      }
    );
  }

  options.push(
    {
      url: '/selling-process',
      label: (
        <div className='flex items-center space-x-2'>
          <TagIcon className='h-5 w-5' />
          <span>{t('products:HEADER_BTN_SELL')}</span>
        </div>
      ),
    },
    {
      url: '/products',
      label: (
        <div className='flex items-center space-x-2'>
          <ShoppingCartIcon className='h-5 w-5' />
          <span>{t('products:HEADER_BTN_BUY')}</span>
        </div>
      ),
    }
  );

  if (user) {
    options.push(
      {
        label: (
          <div>
            <MdSettings className='mr-2 inline-block' />
            <span>{t('products:Settings')}</span>
          </div>
        ),
        url: '/my/settings',
      },
      {
        label: (
          <div>
            <MdLogout className='mr-2 inline-block' />
            <span>{t('products:LOGOUT')}</span>
          </div>
        ),
        url: '/api/auth/logout',
      }
    );

    if (showAdminLinks) {
      options.push({
        label: t('mygindumac:Users'),
        url: '/my/admin/users',
      });
    }
  }

  return { options, showAdminLinks, isMyGindumacPage };
}

export default function UserMenu({
  isNavbarTransparent,
}: {
  fullWidth?: boolean;
  isNavbarTransparent?: boolean;
}) {
  const [isOpen, setIsOpen] = useState(false);
  const { user } = useUser();
  const router = useRouter();
  const { options } = useUserDropdownOptions();
  const { width: windowWidth } = useWindowSize();
  const { t } = useTranslation();

  if (!user) {
    return router.pathname === '/my' ? (
      <NavLink href={'/api/auth/login'} label={t('products:Login')} />
    ) : (
      <Link href='/my'>
        <a className='flex h-12 w-12 items-center justify-center space-x-2 rounded-full border-2 px-4 py-2 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'>
          <span className='text-xl'>
            <UserIcon
              className={classNames(
                'h-8 w-8',
                isNavbarTransparent ? 'text-white' : 'text-brand'
              )}
            />
          </span>
        </a>
      </Link>
    );
  }

  return (
    <div
      className={classNames({
        'w-full max-w-[8rem] text-center lg:text-right': true,
        'flex-1': !user,
      })}
      id='user-dropdown'
    >
      <button
        className='flex h-12 w-12 items-center justify-center space-x-2 rounded-full border-2 px-4 py-2 text-sm font-medium hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75'
        onClick={() => {
          if (windowWidth < 1535) {
            setIsOpen(!isOpen);
          } else {
            router.push('/my/dashboard');
          }
        }}
      >
        <span className='text-xl'>
          <UserIcon
            className={classNames(
              'h-8 w-8',
              isNavbarTransparent ? 'text-white' : 'text-brand'
            )}
          />
        </span>
      </button>

      <MySidebar isOpen={isOpen} setIsOpen={setIsOpen} options={options} />
    </div>
  );
}
