/**
 * Google Tag Manager
 * The global gtag is loaded from TagManager.tsx
 */

import { ProductItem } from '@/lib/types/api';

export const GTM_ID =
  process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID ?? 'GTM-TJ5B9RD';

// telling typescript that yes there is a global variable called dataLayer and gtag
declare global {
  /* eslint-disable unused-imports/no-unused-vars */
  interface Window {
    dataLayer: Record<string, any>[];
    gtag: any;
  }
}

export const pageview = (url: string): void => {
  pushDataLayer('pageview', {
    url: url,
    title: document && document.title,
  });
};

interface GoogleTagManagerEvents {
  (event: 'formInit', data: { formType: string }): void;
  (event: 'formSubmission', data: { formType: string; locale?: string }): void;
  (event: 'pageview', data: { url: string; title: string }): void;
  (event: 'buttonClick', data: { label: string }): void;
  (
    event: 'begin_checkout',
    data: {
      formType: string;
      ecommerce: {
        value: number;
        currency: string;
        items: {
          item_id: string;
          item_name: string;
          currency: string;
          item_brand: string;
          item_category: string;
          price: number;
          quantity: number;
        }[];
      };
    }
  ): void;
  // (event: string, data: unknown): void; // fallback for other options
}
export function analyticsOpenNewsletterSub() {
  pushDataLayer('buttonClick', {
    label: 'Sign Up CLICK',
  });
}

export function getGTMProductData(product: ProductItem) {
  return {
    item_id: product.id,
    item_name: product.model,
    currency: product.currency,
    item_brand: product.brand.name,
    item_category: product.machine_categories[0]?.name,
    price: product.price,
    quantity: 1,
  };
}

export const pushDataLayer: GoogleTagManagerEvents = (event, data) => {
  if (
    process.env.NEXT_PUBLIC_ENABLE_TRACKING &&
    process.env.NEXT_PUBLIC_ENABLE_TRACKING != 'false' &&
    typeof window !== 'undefined' &&
    typeof window.dataLayer !== 'undefined'
  ) {
    const eventData: { event: string; data?: unknown } = {
      event,
    };
    if (data) {
      eventData.data = data;
    }
    console.log('Analytics', eventData);
    window.dataLayer.push(eventData);
  } else {
    console.log(`error: dataLayer does not exist`);
  }
};

export function gtagContactEvent() {
  pushDataLayer('buttonClick', {
    label: 'Email Contact Link',
  });
}

function analyticsBeginCheckout(formType: string, product: ProductItem) {
  pushDataLayer('begin_checkout', {
    formType,
    ecommerce: {
      value: product.price,
      currency: product.currency,
      items: [getGTMProductData(product)],
    },
  });
}
