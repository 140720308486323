import Image from 'next/image';
import Link from 'next/link';
import { ReactNode } from 'react';

export function MyGindumacLogo({ label }: { label?: ReactNode }) {
  return (
    <div className='flex max-w-full items-end justify-center'>
      <Link href={'/'} passHref>
        <div className='relative h-10 w-40 cursor-pointer lg:h-14 lg:w-64'>
          <Image
            src={'/mygindumac/logo.png'}
            alt='MyGINDUMAC logo'
            layout='fill'
          />
          {label && (
            <div className='absolute -bottom-4 right-2 mb-2'>{label}</div>
          )}
        </div>
      </Link>
    </div>
  );
}
