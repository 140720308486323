import clsx from 'clsx';
import Link from 'next-translate-routes-multi-domain/link';
import { ReactNode, useState } from 'react';
import {
  ImFacebook,
  ImGoogle,
  ImInstagram,
  ImLinkedin,
  ImTwitter,
  ImYoutube,
} from 'react-icons/im';
import { MdMenu } from 'react-icons/md';
import useScrollPosition from '@/lib/hooks/useScrollPosition';
import LanguageSelector from '@/components/i18n/LanguageSelector';
import useTranslation from 'next-translate/useTranslation';
import { useRouter } from 'next/router';
import { MobileNavbar } from './MobileNavbar';
import Drawer from '../ui/Modals/Drawer/Drawer';
import NewsletterForm from '../forms/NewsLetter/NewsletterForm';
import {
  getNewsRoomLink,
  telephoneLink,
} from '@/lib/data-transformer/gindumacUtils';
import { setIsNewsletterModalOpen } from '../forms/NewsLetter/modalSlice';
import { useAppDispatch } from '@/redux/hooks';
import {
  analyticsOpenNewsletterSub,
  gtagContactEvent,
} from '@/lib/utilities/analytics/gtm';
import CookieConsent from '../Analytics/CookieConsent';
import { analyticsButtonClick } from '../Analytics/GoogleAnalytics';
import UserMenu from '../mygindumac/UserMenu';
import GlobalContactUsButton from '../mygindumac/ui/GlobalContactUsButton';
import Sidebar from '../ui/Menus/Sidebar';
import { Dropdown } from '../ui/Dropdown';
import { NavLink } from '../ui/Links/NavLink';
import { BackToTop } from './BackToTop';
import { Logo } from './Logo';
import { ContactNavbarSlider } from './ContactNavbarSlider';

export default function Layout({
  isNavbarTransparent,
  disableNewsletterForm,
  disableBackToTop,
  children,
  logo,
  links,
}: {
  isNavbarTransparent?: boolean;
  children: ReactNode;
  disableNewsletterForm?: boolean;
  disableBackToTop?: boolean;
  logo?: ReactNode;
  links?: ReactNode[];
}) {
  const [showMobileNavbar, setShowMobileNavbar] = useState(false);
  const scrollPosition = useScrollPosition();
  isNavbarTransparent = isNavbarTransparent && scrollPosition < 10;
  const { t } = useTranslation('products');
  const { locale } = useRouter();
  const contactEmail = t('products:HEADER_SUPPORT_CONTACT_WITH_US_EMAIL');
  const dispatch = useAppDispatch();

  return (
    <>
      {/* Navbar Gindumac */}
      <div className=''>
        <header
          className={clsx(
            'no-print top-0 z-30 w-full transition duration-200',
            isNavbarTransparent ? '!fixed bg-transparent' : 'sticky bg-white'
          )}
        >
          <nav
            className={clsx(
              'z-10 flex h-20 w-full items-center justify-between px-4 md:px-8',
              { 'border-b-2': !isNavbarTransparent }
            )}
          >
            <button
              className='xl:hidden'
              onClick={() => setShowMobileNavbar((isOpen) => !isOpen)}
              aria-label='Open mobile navbar'
            >
              <MdMenu
                className={clsx('h-10 w-10', {
                  'text-white': isNavbarTransparent,
                })}
              />
            </button>
            {logo ?? <Logo isWhite={isNavbarTransparent} />}
            {!links ? (
              <>
                <div
                  className={clsx(
                    'text-nav ml-auto mr-4 hidden space-x-4 divide-x-2 border-r pr-4 xl:flex',
                    { '!text-white': isNavbarTransparent }
                  )}
                >
                  <button
                    className='flex items-center rounded bg-brand-800 px-4 py-1 uppercase text-white transition duration-300 hover:bg-[#CCCCCC] hover:text-brand-500'
                    onClick={() => {
                      analyticsOpenNewsletterSub();
                      dispatch(setIsNewsletterModalOpen(true));
                    }}
                  >
                    {t('products:HEADER_BTN_SIGN_UP')}
                  </button>
                  <div className='flex items-center space-x-4 pl-4'>
                    <Dropdown
                      title={t('products:HEADER_ABOUT_GINDUMAC')}
                      links={[
                        {
                          href: '/who-we-are',
                          title: t('products:CORPORATE_WHO_WE_ARE'),
                        },
                        {
                          href: '/machine-categories',
                          title: t(
                            'products:CORPORATE_MACHINE_CATEGORIES_TITLE'
                          ),
                        },
                      ]}
                    />
                    <Dropdown
                      title={t('products:SERVICES_GINDUMAC_MENU')}
                      links={[
                        {
                          href: '/we-buy-your-project',
                          title: t('products:BUY_YOUR_PROJECT_TITLE'),
                        },
                        {
                          href: '/financing-options',
                          title: t(
                            'products:CORPORATE_FINANCING_OPTIONS_TITLE'
                          ),
                        },
                        {
                          href: '/logistic-services',
                          title: t(
                            'products:CORPORATE_LOGISTICS_SERVICES_TITLE'
                          ),
                        },
                        {
                          href: '/premium-inspection',
                          title: t(
                            'products:CORPORATE_PREMIUM_INSPECTION_TITLE'
                          ),
                        },
                      ]}
                    />
                    <Link href='/careers'>
                      <a>{t('products:CAREERS_TITLE')}</a>
                    </Link>
                    <a
                      href={getNewsRoomLink(locale)}
                      target='_blank'
                      rel='noreferrer'
                    >
                      {t('products:NEWSROOM_NAME')}
                    </a>
                    <ContactNavbarSlider contactEmail={contactEmail} />
                  </div>
                  <div className='flex items-center space-x-4 pl-4'>
                    <NavLink
                      href='/selling-process'
                      color='secondary'
                      label={t('products:HEADER_BTN_SELL')}
                      classes='text-brand-500'
                    />
                    <NavLink
                      href='/products'
                      color='brand'
                      label={t('products:HEADER_BTN_BUY')}
                    />
                  </div>
                </div>
                <div className='flex items-center space-x-4'>
                  <UserMenu isNavbarTransparent={isNavbarTransparent} />
                  <LanguageSelector isNavbarTransparent={isNavbarTransparent} />
                </div>
              </>
            ) : (
              <div className='flex items-center space-x-4 pl-4'>
                {links.map((link) => link)}
              </div>
            )}
          </nav>
          <Drawer
            isOpen={showMobileNavbar}
            setIsOpen={() => setShowMobileNavbar(false)}
            size='xs'
          >
            <MobileNavbar />
          </Drawer>
        </header>
        <div className='flex'>
          <Sidebar />
          <div
            className={clsx('flex-1 tracking-wider', {
              'brightness-50': showMobileNavbar,
            })}
            onClick={() => setShowMobileNavbar(false)}
          >
            {children}
            <GlobalContactUsButton />
            <footer className='no-print'>
              {!disableNewsletterForm && <NewsletterForm />}
              {!disableBackToTop && <BackToTop />}
              <div className='bg-white px-4 py-8 text-gray-700'>
                <div className='flex w-full flex-col items-center justify-around space-y-4 md:flex-row md:space-y-0'>
                  <ul className='text-center md:text-left'>
                    <li>GINDUMAC GmbH</li>
                    <li>Trippstadter Strasse 110</li>
                    <li>67663 Kaiserslautern</li>
                    <li>{t('products:Germany')}</li>
                  </ul>
                  <ul className='text-center uppercase md:text-left'>
                    <li>
                      <Link href='/who-we-are'>
                        <a>{t('products:HEADER_ABOUT_GINDUMAC')}</a>
                      </Link>
                    </li>
                    <li>
                      <Link href='/careers'>
                        <a>{t('products:CAREERS_TITLE')}</a>
                      </Link>
                    </li>
                    <li>
                      <a
                        target='_blank'
                        href={getNewsRoomLink(locale)}
                        rel='noreferrer'
                      >
                        NEWSROOM
                      </a>
                    </li>
                  </ul>
                  <ul className='text-center uppercase md:text-left'>
                    <li>
                      <Link href='/legal'>
                        <a>{t('seo:FOOTER_LEGAL')}</a>
                      </Link>
                    </li>
                    <li>
                      <Link href='/general-terms-and-conditions-of-sale'>
                        <a>{t('seo:FOOTER_TERMS_AND_CONDITIONS_OF_SALE')}</a>
                      </Link>
                    </li>
                    <li>
                      <Link href='/general-terms-and-conditions-of-purchase'>
                        <a>
                          {t('seo:FOOTER_TERMS_AND_CONDITIONS_OF_PURCHASE')}
                        </a>
                      </Link>
                    </li>
                    <li>
                      <Link href='/privacy'>{t('seo:FOOTER_PRIVACY')}</Link>
                    </li>
                    <li>
                      <Link href='/cookie-policy'>
                        {t('products:FOOTER_COOKIES_POLICY')}
                      </Link>
                    </li>
                  </ul>
                  <ul className='text-center md:text-left'>
                    <li>
                      <a
                        href={'mailto:' + contactEmail}
                        onClick={gtagContactEvent}
                      >
                        {contactEmail}
                      </a>
                    </li>
                    <li>
                      <a href={telephoneLink(t('products:HEADER_PHONE'))}>
                        {t('products:HEADER_PHONE')}
                      </a>
                    </li>
                  </ul>
                </div>
                <hr className='my-6' />
                <div className='flex w-full justify-around'>
                  <ul className='flex flex-row flex-wrap items-center space-x-4'>
                    <li className='w-full md:w-auto'>
                      {t('products:FOOTER_TITLE_SOCIAL')}
                    </li>
                    <li>
                      <a
                        href='https://www.linkedin.com/company/gindumac-gmbh'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Linkedin profile'
                      >
                        <ImLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.google.com/maps/place/GINDUMAC+GmbH/@49.4323562,7.7492869,17z/data=!3m2!4b1!5s0x479612ad5fa94551:0xa48b61c0f94b7943!4m6!3m5!1s0x479612ad5c0fe2b3:0xb7c60fb5e4aa40e7!8m2!3d49.4323528!4d7.7537716!16s%2Fg%2F11swc9wcbn?coh=164777&entry=tt&shorturl=1'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Google Plus'
                      >
                        <ImGoogle />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.instagram.com/gindumac/'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Xing profile'
                      >
                        <ImInstagram />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.youtube.com/channel/UCMZfx7eN3p4q01FSTFFf3Lw'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Xing profile'
                      >
                        <ImYoutube />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://www.facebook.com/gindumac/'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Facebook page'
                      >
                        <ImFacebook />
                      </a>
                    </li>
                    <li>
                      <a
                        href='https://twitter.com/gindumac'
                        target='_blank'
                        rel='noreferrer'
                        aria-label='Twitter profile'
                      >
                        <ImTwitter />
                      </a>
                    </li>
                  </ul>
                  <ul className='flex flex-col items-center space-x-4 md:flex-row'>
                    <li>
                      <span>{t('products:FOOTER_TITLE_MEMBERSHIP')}</span>
                    </li>
                    <li>
                      <a
                        href='http://www.vdwf.de'
                        target='_blank'
                        rel='noreferrer'
                        onClick={() => {
                          analyticsButtonClick('VDWF logo');
                        }}
                      >
                        <div className='h-12'>
                          {/* eslint-disable-next-line @next/next/no-img-element */}
                          <img
                            src='/images/vdwf_logo_werkzeugmacher_rgb.jpg'
                            alt='vdwf_logo_werkzeugmacher'
                          />
                        </div>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </footer>
          </div>
        </div>
      </div>
      <CookieConsent />
    </>
  );
}
