import useTranslation from 'next-translate/useTranslation';

/**
 * Same as useTranslation but the key without namespace will be used as default value
 * @returns {t: (key: string, options?: any) => string}
 * @example
 * const { t } = useTranslate();
 * t('PRODUCT_RELATED_HEADER') // PRODUCT_RELATED_HEADER
 */
export default function useTranslate() {
  const { t } = useTranslation();

  return {
    t: (key: string, options?: any) =>
      t(key, options, { default: key.includes(':') ? key.split(':')[1] : key }),
  };
}
