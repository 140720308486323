import { AiOutlineSetting } from 'react-icons/ai';

function LoadSpinner() {
  return (
    <div className='mx-auto h-screen w-full bg-white'>
      {/* Loading animation */}
      <div className='flex h-full flex-col items-center justify-center space-y-4'>
        <AiOutlineSetting className='h-20 w-20 animate-[spin_3s_ease-in-out_infinite] text-brand-800' />
      </div>
    </div>
  );
}

export default LoadSpinner;
