import classNames from 'classnames';
import { Path } from 'react-hook-form';
import { ErrorMessage } from './Input';

type SelectProps<FormTypes> = {
  name: Path<FormTypes>;
  errors: any;
  register: any;
  options: { value: string; label: string; key?: string }[];
  label?: string;
  value?: string;
  validation?: {
    required?: boolean;
    pattern?: { value: RegExp; message: string };
  };
};

export function Select<FormTypes>({
  name,
  errors,
  register,
  options,
  label,
  value,
  validation,
}: SelectProps<FormTypes>) {
  if (label) {
    validation = validation || {};
    validation.pattern = {
      value: /\S/,
      message: 'Please select a option',
    };
  }

  return (
    <div className=''>
      <select
        id={name}
        className={classNames(
          'form-input h-max w-full text-[0.7rem] invalid:text-slate-300 md:text-[1rem]',
          {
            'text-slate-500': !value,
            'border-b-2 border-red-500': errors?.[name],
          }
        )}
        {...register(name, validation)}
        defaultValue=''
      >
        {label && (
          <option value='' disabled>
            {label}
          </option>
        )}

        {options.map((option) => (
          <option key={option.key || option.value} value={option.value}>
            {option.label.toUpperCase()}
          </option>
        ))}
      </select>
      <div className='flex justify-end'>
        <ErrorMessage errors={errors} name={name} />
      </div>
    </div>
  );
}
