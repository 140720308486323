const DOMAINS = {
  'www.gindumac.com': {
    tld: 'com',
    locale: 'en-US',
    store: 'COM',
    production: true,
    country: 'GLOBAL',
    showHotjar: true,
    hotjarSettings: 'hjid:462013,hjsv:6',
    gtmSettings: 'TJ5B9RD',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'www.gindumac.de': {
    tld: 'de',
    locale: 'de-DE',
    store: 'COM',
    production: true,
    country: 'GERMANY',
    showHotjar: true,
    hotjarSettings: 'hjid:641004,hjsv:6',
    gtmSettings: 'P4M6NQJ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.es': {
    tld: 'es',
    locale: 'es-ES',
    store: 'COM',
    production: true,
    country: 'SPAIN',
    showHotjar: true,
    hotjarSettings: 'hjid:653057,hjsv:6',
    gtmSettings: 'TBZTTXJ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.in': {
    tld: 'in',
    locale: 'en-IN',
    store: 'COM',
    production: true,
    country: 'INDIA',
    showHotjar: true,
    hotjarSettings: 'hjid:660504,hjsv:6',
    gtmSettings: '58ZZTBM',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'www.gindumac.it': {
    tld: 'it',
    locale: 'it-IT',
    store: 'COM',
    production: true,
    country: 'ITALY',
    showHotjar: true,
    hotjarSettings: 'hjid:741265,hjsv:6',
    gtmSettings: 'TMRS8T6',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.at': {
    tld: 'at',
    locale: 'de-AT',
    store: 'COM',
    production: true,
    country: 'AUSTRIA',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'M76H8KZ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'www.gindumac.pl': {
    tld: 'pl',
    locale: 'pl-PL',
    store: 'COM',
    production: true,
    country: 'POLAND',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'MMCX9MM',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.se': {
    tld: 'se',
    locale: 'sv-SE',
    store: 'COM',
    production: true,
    country: 'SWEDEN',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'PPXMT85',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.co.uk': {
    tld: 'co.uk',
    locale: 'en-GB',
    store: 'COM',
    production: true,
    country: 'UNITED KINGDOM',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'K4P9NPF',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'www.gindumac.fi': {
    tld: 'fi',
    locale: 'fi-FI',
    store: 'COM',
    production: true,
    country: 'FINLAND',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: '59QM544',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.nl': {
    tld: 'nl',
    locale: 'nl-NL',
    store: 'COM',
    production: true,
    country: 'NETHERLANDS',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'N4TDVBT',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.hr': {
    tld: 'hr',
    locale: 'hr-HR',
    store: 'COM',
    production: true,
    country: 'CROATIA',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.com.tr': {
    tld: 'com.tr',
    locale: 'tr-TR',
    store: 'COM',
    production: true,
    country: 'TURKEY',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.cz': {
    tld: 'cz',
    locale: 'cs-CZ',
    store: 'COM',
    production: true,
    showHotjar: false,
    hotjarSettings: '',
    country: 'CZECH REPUBLIC',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.hu': {
    tld: 'hu',
    locale: 'hu-HU',
    store: 'COM',
    production: true,
    country: 'HUNGARY',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.lv': {
    tld: 'lv',
    locale: 'lv-LV',
    store: 'COM',
    production: true,
    country: 'LATVIA',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.lt': {
    tld: 'lt',
    locale: 'lt-LT',
    store: 'COM',
    production: true,
    country: 'LITHUANIA',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.ee': {
    tld: 'ee',
    locale: 'et-EE',
    store: 'COM',
    production: true,
    country: 'ESTONIA',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.pt': {
    tld: 'pt',
    locale: 'pt-PT',
    store: 'COM',
    production: true,
    country: 'PORTUGAL',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.fr': {
    tld: 'fr',
    locale: 'fr-FR',
    store: 'COM',
    production: true,
    country: 'FRANCE',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.ie': {
    tld: 'ie',
    locale: 'en-IE',
    store: 'COM',
    production: true,
    country: 'IRELAND',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'www.gindumac.com.mx': {
    tld: 'com.mx',
    locale: 'es-MX',
    store: 'COM',
    production: true,
    country: 'MEXICO',
    showHotjar: true,
    hotjarSettings: 'hjid:653057,hjsv:6',
    gtmSettings: 'TBZTTXJ',
    newsroom: true,
    showAllCountryFlags: false,
    allAlternate: false,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'www.gindumac.ru': {
    tld: 'ru',
    locale: 'ru-RU',
    store: 'COM',
    production: true,
    country: 'RUSSIA',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'TBZTTXJ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'www.gindumac.com.ua': {
    tld: 'com.ua',
    locale: 'uk-UA',
    store: 'COM',
    production: true,
    country: 'UKRAINE',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'TBZTTXJ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'staging.gindumac.com': {
    tld: 'com',
    locale: 'en-US',
    store: 'COM',
    production: true,
    country: 'GLOBAL',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'TJ5B9RD',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'staging.gindumac.de': {
    tld: 'de',
    locale: 'de-DE',
    store: 'COM',
    production: true,
    country: 'GERMANY',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'P4M6NQJ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.es': {
    tld: 'es',
    locale: 'es-ES',
    store: 'COM',
    production: true,
    country: 'SPAIN',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'TBZTTXJ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.in': {
    tld: 'in',
    locale: 'en-IN',
    store: 'COM',
    production: true,
    country: 'INDIA',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: '58ZZTBM',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'staging.gindumac.it': {
    tld: 'it',
    locale: 'it-IT',
    store: 'COM',
    production: true,
    country: 'ITALY',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'TMRS8T6',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.at': {
    tld: 'at',
    locale: 'de-AT',
    store: 'COM',
    production: true,
    country: 'AUSTRIA',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'M76H8KZ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'staging.gindumac.pl': {
    tld: 'pl',
    locale: 'pl-PL',
    store: 'COM',
    production: true,
    country: 'POLAND',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'MMCX9MM',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.se': {
    tld: 'se',
    locale: 'sv-SE',
    store: 'COM',
    production: true,
    country: 'SWEDEN',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'PPXMT85',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.co.uk': {
    tld: 'co.uk',
    locale: 'en-GB',
    store: 'COM',
    production: true,
    country: 'UNITED KINGDOM',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'K4P9NPF',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'staging.gindumac.fi': {
    tld: 'fi',
    locale: 'fi-FI',
    store: 'COM',
    production: true,
    country: 'FINLAND',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: '59QM544',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.nl': {
    tld: 'nl',
    locale: 'nl-NL',
    store: 'COM',
    production: true,
    country: 'NETHERLANDS',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'N4TDVBT',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.hr': {
    tld: 'hr',
    locale: 'hr-HR',
    store: 'COM',
    production: true,
    country: 'CROATIA',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.com.tr': {
    tld: 'com.tr',
    locale: 'tr-TR',
    store: 'COM',
    production: true,
    country: 'TURKEY',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.cz': {
    tld: 'cz',
    locale: 'cs-CZ',
    store: 'COM',
    production: true,
    country: 'CZECH REPUBLIC',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.hu': {
    tld: 'hu',
    locale: 'hu-HU',
    store: 'COM',
    production: true,
    country: 'HUNGARY',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.lv': {
    tld: 'lv',
    locale: 'lv-LV',
    store: 'COM',
    production: true,
    country: 'LATVIA',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.lt': {
    tld: 'lt',
    locale: 'lt-LT',
    store: 'COM',
    production: true,
    country: 'LITHUANIA',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.ee': {
    tld: 'ee',
    locale: 'et-EE',
    store: 'COM',
    production: true,
    country: 'ESTONIA',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.pt': {
    tld: 'pt',
    locale: 'pt-PT',
    store: 'COM',
    production: true,
    country: 'PORTUGAL',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.fr': {
    tld: 'fr',
    locale: 'fr-FR',
    store: 'COM',
    production: true,
    country: 'FRANCE',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.ie': {
    tld: 'ie',
    locale: 'en-IE',
    store: 'COM',
    production: true,
    country: 'IRELAND',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'W7MZMXQ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'staging.gindumac.com.mx': {
    tld: 'com.mx',
    locale: 'es-MX',
    store: 'COM',
    production: true,
    country: 'MEXICO',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'TBZTTXJ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'staging.gindumac.ru': {
    tld: 'ru',
    locale: 'ru-RU',
    store: 'COM',
    production: true,
    country: 'RUSSIA',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'TBZTTXJ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'staging.gindumac.com.ua': {
    tld: 'com.ua',
    locale: 'uk-UA',
    store: 'COM',
    production: true,
    country: 'UKRAINE',
    showHotjar: false,
    hotjarSettings: '',
    gtmSettings: 'TBZTTXJ',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'dev2.gindumac.com': {
    tld: 'com',
    locale: 'en-US',
    store: 'COM',
    production: false,
    country: 'GLOBAL',
    showHotjar: false,
    hotjarSettings: '',
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'dev2.gindumac.de': {
    tld: 'de',
    locale: 'de-DE',
    store: 'COM',
    production: false,
    country: 'GERMANY',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.es': {
    tld: 'es',
    locale: 'es-ES',
    store: 'COM',
    production: false,
    country: 'SPAIN',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.in': {
    tld: 'in',
    locale: 'en-IN',
    store: 'COM',
    production: false,
    country: 'INDIA',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'dev2.gindumac.it': {
    tld: 'it',
    locale: 'it-IT',
    store: 'COM',
    production: false,
    country: 'ITALY',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.at': {
    tld: 'at',
    locale: 'de-AT',
    store: 'COM',
    production: false,
    country: 'AUSTRIA',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'dev2.gindumac.pl': {
    tld: 'pl',
    locale: 'pl-PL',
    store: 'COM',
    production: false,
    country: 'POLAND',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.se': {
    tld: 'se',
    locale: 'sv-SE',
    store: 'COM',
    production: false,
    country: 'SWEDEN',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.co.uk': {
    tld: 'co.uk',
    locale: 'en-GB',
    store: 'COM',
    production: false,
    country: 'UNITED KINGDOM',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'dev2.gindumac.fi': {
    tld: 'fi',
    locale: 'fi-FI',
    store: 'COM',
    production: false,
    country: 'FINLAND',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.nl': {
    tld: 'nl',
    locale: 'nl-NL',
    store: 'COM',
    production: false,
    country: 'NETHERLANDS',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.hr': {
    tld: 'hr',
    locale: 'hr-HR',
    store: 'COM',
    production: false,
    country: 'CROATIA',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.com.tr': {
    tld: 'com.tr',
    locale: 'tr-TR',
    store: 'COM',
    production: false,
    country: 'TURKEY',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'dev2.gindumac.cz': {
    tld: 'cz',
    locale: 'cs-CZ',
    store: 'COM',
    production: false,
    country: 'CZECH REPUBLIC',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.hu': {
    tld: 'hu',
    locale: 'hu-HU',
    store: 'COM',
    production: false,
    country: 'HUNGARY',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.lv': {
    tld: 'lv',
    locale: 'lv-LV',
    store: 'COM',
    production: false,
    country: 'LATVIA',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.lt': {
    tld: 'lt',
    locale: 'lt-LT',
    store: 'COM',
    production: false,
    country: 'LITHUANIA',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.ee': {
    tld: 'ee',
    locale: 'et-EE',
    store: 'COM',
    production: false,
    country: 'ESTONIA',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.pt': {
    tld: 'pt',
    locale: 'pt-PT',
    store: 'COM',
    production: false,
    country: 'PORTUGAL',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.fr': {
    tld: 'fr',
    locale: 'fr-FR',
    store: 'COM',
    production: false,
    country: 'FRANCE',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.ie': {
    tld: 'ie',
    locale: 'en-IE',
    store: 'COM',
    production: false,
    country: 'IRELAND',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.com.mx': {
    tld: 'com.mx',
    locale: 'es-MX',
    store: 'COM',
    production: false,
    country: 'MEXICO',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: true,
    showPaymentOptions: true,
  },
  'dev2.gindumac.ru': {
    tld: 'ru',
    locale: 'ru-RU',
    store: 'COM',
    production: false,
    country: 'RUSSIA',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
  'dev2.gindumac.com.ua': {
    tld: 'com.ua',
    locale: 'uk-UA',
    store: 'COM',
    production: false,
    country: 'UKRAINE',
    showHotjar: false,
    newsroom: true,
    showAllCountryFlags: true,
    allAlternate: true,
    showSkeleton: true,
    showWhatsapp: false,
    showOriginalTranslation: false,
    showPaymentOptions: true,
  },
};

const LOCALES = process.env.NEXT_PUBLIC_TEST_LIMITED_BUILD
  ? ['en-US', 'de-DE']
  : [
      'cs-CZ',
      'de-AT',
      'de-DE',
      'en-GB',
      'en-IE',
      'en-IN',
      'en-US',
      'es-ES',
      'es-MX',
      'et-EE',
      'fi-FI',
      'hr-HR',
      'hu-HU',
      'it-IT',
      'lt-LT',
      'lv-LV',
      'nl-NL',
      'pl-PL',
      'pt-PT',
      'ru-RU',
      'sv-SE',
      'tr-TR',
      'fr-FR',
      'uk-UA',
    ];

const DOMAINS_ARRAY = Object.keys(DOMAINS).map((key) => ({
  ...DOMAINS[key],
  domain: key,
}));

module.exports = {
  DOMAINS,
  DOMAINS_ARRAY,
  LOCALES,
};
