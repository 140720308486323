import { createSlice, PayloadAction } from '@reduxjs/toolkit';

type yesOrNo = 'yes' | 'no' | null;

export interface NewsLetterModalState {
  hasAccepted: yesOrNo;
}

export const initialState: NewsLetterModalState = {
  hasAccepted: null,
};

export const cookieConsentSlice = createSlice({
  name: 'cookieConsent',
  initialState,
  reducers: {
    setHasAccepted: (state, action: PayloadAction<yesOrNo>) => {
      state.hasAccepted = action.payload;
    },
  },
});

export const { setHasAccepted } = cookieConsentSlice.actions;

export default cookieConsentSlice.reducer;
