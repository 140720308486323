// AJAX to get the user company name and cache

import { useUser } from '@auth0/nextjs-auth0/client';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

export interface UserMetadata {
  city?: string;
  companyCity?: string;
  companyCountry?: string;
  companyIndustry?: string;
  companyLine1?: string;
  companyName?: string;
  companyPhone?: string;
  companyPostalCode?: string;
  companySite?: string;
  companyState?: string;
  companyEmail?: string;
  country?: string;
  firstName?: string;
  lastName?: string;
  line1?: string;
  phone?: string;
  postalCode?: string;
  state?: string;
  title?: string;
  notificationsProjEmail?: boolean;
  notificationsNewsletter?: boolean;
  notificationsPriceDrop?: boolean;
  missingMetadataVerification?: boolean;
  locale?: string;
  'circular-club'?: boolean;
}

export default function useUserMetadata() {
  const { user } = useUser();
  const {
    data: metadata,
    refetch,
    isLoading,
  } = useQuery<UserMetadata>(['user_metadata'], () => fetchMetadata(), {
    refetchOnWindowFocus: false,
    enabled: !!user,
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchInterval: false,
  });

  return { metadata, refetch, isLoading };
}

export function fetchMetadata() {
  return fetch('/api/users/metadata')
    .then((res) => res.json())
    .then((data) => data);
}

export function useUserMetadataMutation() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (metadata: Partial<UserMetadata>) =>
      updateUserMetadata(metadata),
    onSuccess: () => {
      queryClient.invalidateQueries(['user_metadata']);
    },
  });
}

export function updateUserMetadata(metadata: Partial<UserMetadata>) {
  return fetch('/api/users/metadata', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(metadata),
  }).then((res) => res.json());
}
