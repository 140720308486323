/*
A modal that will suggest the user to change the country if we have a domain that matches the country of the user.
We will get the users country from a request to /api/getcountry and the domains from getDomains()
*/

import { DomainsArrayItem } from '@/lib/data/domains';
import { Dialog } from '@headlessui/react';
import { useQuery } from '@tanstack/react-query';
import { Link, useRouter } from 'next-translate-routes-multi-domain';
import useTranslation from 'next-translate/useTranslation';
import { LocaleOption, useLocaleFlag } from '../i18n/LanguageSelector';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash-es';
import { getDomainsByEnv } from '@/lib/server/getDomains';
import { isTesting } from '@/lib/common';
import store from 'store2';

interface GetCountryI {
  country: string;
}

const DISABLE_DOMAIN_SUGGESTION_COOKIE = 'country-selection-popup';

export const ChangeDomainSuggestion = () => {
  const { pathname, locale } = useRouter();
  const [hasDismissed, setHasDismissed] = useState<boolean | null>(null);
  const [suggestive_domain, setSuggestiveDomain] =
    useState<DomainsArrayItem | null>(null);
  const [suggestiveDomainFlagData, setSuggestiveDomainFlagData] =
    useState<LocaleOption | null>(null);
  const { t } = useTranslation();
  const disable =
    pathname.includes('product') ||
    isEmpty(locale) ||
    hasDismissed ||
    isTesting();

  useEffect(() => {
    setHasDismissed(store.get(DISABLE_DOMAIN_SUGGESTION_COOKIE) == 'stay');
  }, []);

  const { data: country } = useQuery(
    ['getCountry'],
    () => {
      return fetch(
        (process.env.NEXT_PUBLIC_GET_COUNTRY_API ??
          'https://staging.gindumac.com') + '/api/getcountry'
      ).then((r) => r.json()) as Promise<GetCountryI>;
    },
    {
      enabled: !disable,
      staleTime: 1000 * 60 * 60 * 24 * 7 * 4,
    }
  );

  const flags = useLocaleFlag();
  useEffect(() => {
    if (!isEmpty(country?.country) && hasDismissed !== true) {
      const domains = getDomainsByEnv(
        (process.env.NEXT_PUBLIC_APP_ENV as 'production' | 'staging') ??
          'develop'
      );
      const suggestive_domain = domains.find((d) =>
        d.tld.includes(country?.country.toLowerCase() ?? 'en-US')
      );
      if (suggestive_domain) {
        setSuggestiveDomain(suggestive_domain);
      }
      const suggestiveDomainFlagData = flags.find(
        (f) => f.locale === suggestive_domain?.locale
      );
      if (suggestiveDomainFlagData) {
        setSuggestiveDomainFlagData(suggestiveDomainFlagData);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [country]);

  useEffect(() => {
    if (
      suggestive_domain &&
      store.get(DISABLE_DOMAIN_SUGGESTION_COOKIE) !== 'stay'
    ) {
      if (getCurrentTLD() !== suggestive_domain.tld) {
        setisOpen(true);
      } else {
        store.get(DISABLE_DOMAIN_SUGGESTION_COOKIE, 'stay');
      }
    } else {
      setisOpen(false);
    }
  }, [suggestive_domain]);

  const [isOpen, setisOpen] = useState(!isEmpty(suggestive_domain));

  const currentLanguageFlagInfo = flags.find((o) => o.locale === locale);

  function handleClose() {
    store.set(DISABLE_DOMAIN_SUGGESTION_COOKIE, 'stay');
    setisOpen(false);
  }

  const origin =
    typeof window !== 'undefined' && window.location.host
      ? window.location.host
      : 'GINDUMAC';

  return (
    <Dialog open={isOpen} onClose={handleClose} className='relative z-50'>
      <div className='fixed inset-0 bg-black/70' aria-hidden='true' />
      <div className='fixed inset-0 flex items-center justify-center p-4'>
        <Dialog.Panel className='w-full max-w-xl rounded bg-white p-4 md:p-12'>
          <Dialog.Title className='flex items-center justify-between'>
            <h1
              className='mx-auto text-center text-muted-500'
              dangerouslySetInnerHTML={{
                __html:
                  t('products:COUNTRY_SELECTION_POPUP_HEADING') +
                  ` ${suggestive_domain?.country}!`,
              }}
            />
            <button className=''>
              {/* close icon */}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                className='h-6 w-6 '
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
                onClick={handleClose}
              >
                <path
                  strokeLinecap='round'
                  strokeLinejoin='round'
                  strokeWidth={2}
                  d='M6 18L18 6M6 6l12 12'
                />
              </svg>
            </button>
          </Dialog.Title>
          <p className='pt-12 text-center'>
            {t('products:COUNTRY_SELECTION_POPUP_CURRENT_DOMAIN') +
              ' ' +
              origin}
            <br />
            {t('products:COUNTRY_SELECTION_POPUP_CURRENT_DOMAIN2')}
          </p>
          <div className='mx-auto max-w-md space-y-4 pt-4'>
            <Link href='/' locale={suggestive_domain?.locale}>
              <span className='flex h-20 w-full cursor-pointer flex-col items-center justify-center space-x-2 border border-gray-300 p-6 font-secondary tracking-wide text-brand-500 no-underline md:h-10 md:flex-row'>
                {suggestiveDomainFlagData && suggestiveDomainFlagData.icon}{' '}
                {t('products:COUNTRY_SELECTION_POPUP_OPTION1')}{' '}
                <span className='font-semibold'>
                  {suggestive_domain?.domain}
                </span>
              </span>
            </Link>
            <button
              onClick={handleClose}
              className='flex h-20 w-full cursor-pointer flex-col items-center justify-center space-x-2 border border-gray-300 p-6 font-secondary tracking-wide text-brand-500 no-underline md:h-10 md:flex-row'
            >
              {currentLanguageFlagInfo && currentLanguageFlagInfo.icon}
              {t('products:COUNTRY_SELECTION_POPUP_OPTION2')}
              <span className='font-semibold'>{origin}</span>
            </button>
          </div>
        </Dialog.Panel>
      </div>
    </Dialog>
  );
};

function getCurrentTLD() {
  if (typeof window !== 'undefined') {
    const host = window.location.host;
    const hostByDots = host.split('.');
    const tld = hostByDots.pop();
    const tld2 = hostByDots.pop();
    return tld2 == 'com' ? tld2 + '.' + tld : tld;
  }
  return 'com';
}
