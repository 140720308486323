import Head from 'next/head';
import Script from 'next/script';

export default function FacebookAnalytics() {
  return (
    <>
      <Head>
        <noscript>
          <noscript>
            {/* eslint-disable-next-line @next/next/no-img-element */}
            <img
              height='1'
              width='1'
              style={{
                display: 'none',
              }}
              alt=''
              src='https://dc.ads.linkedin.com/collect/?pid=98503&fmt=gif'
            />
          </noscript>
        </noscript>
      </Head>
      {/* Linkedin */}
      <Script
        id='fb-script'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
          _linkedin_data_partner_id = "98503"
          (function(){var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})();
          `,
        }}
      />
    </>
  );
}
