import { useUser } from '@auth0/nextjs-auth0/client';
import { setUser } from '@sentry/nextjs';
import { useEffect } from 'react';

export function SentryUserSetter() {
  const { user } = useUser();
  useEffect(() => {
    if (user) {
      setUser({
        email: user.email ?? undefined,
        username: user.name ?? undefined,
      });
    } else {
      setUser(null);
    }
  }, [user]);
  return null;
}
