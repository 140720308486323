import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useCookie } from 'react-use';

export function useGclid() {
  const router = useRouter();
  const [gclidCookie, updateGclidCookie] = useCookie('gclid');

  useEffect(() => {
    if (!router.isReady) return;

    const { gclid: gclidFromQuery } = router.query;

    if (gclidFromQuery && gclidFromQuery !== gclidCookie) {
      updateGclidCookie(gclidFromQuery.toString(), { path: '/' });
    }
  }, [router.isReady, router.query, gclidCookie, updateGclidCookie]);

  return gclidCookie;
}
