import {
  configureStore,
  ThunkAction,
  Action,
  createListenerMiddleware,
} from '@reduxjs/toolkit';

import productFilterReducer from '@/components/forms/Filters/filterSlice';
import modalsReducer from '@/components/forms/NewsLetter/modalSlice';
import cookieConsentReducer from '@/components/Analytics/cookieConsentSlice';

const listenerMiddleware = createListenerMiddleware();

export function makeStore() {
  return configureStore({
    reducer: {
      productFilter: productFilterReducer,
      modals: modalsReducer,
      cookieConsent: cookieConsentReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(listenerMiddleware.middleware),
  });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  AppState,
  unknown,
  Action<string>
>;

export default store;
