import { getCategories } from '@/lib/repository/machinesRepository';
import { Dialog } from '@headlessui/react';
import useTranslation from 'next-translate/useTranslation';
import { useForm } from 'react-hook-form';
import { Input } from '../Input';
import { InputTerms } from '../InputTerms';
import { SelectCountry } from '../SelectCountry';
import { useAppDispatch, useAppSelector } from '@/redux/hooks';
import { setIsNewsletterModalOpen } from './modalSlice';
import axios from 'axios';
import { SuccessCheck } from '../Success/SuccessCheck';
import classNames from 'classnames';
import {
  analyticsOpenNewsletterSub,
  pushDataLayer,
} from '@/lib/utilities/analytics/gtm';
import { useEffect } from 'react';
import useUserMetadata from '@/lib/hooks/useUserData';
import { useUser } from '@auth0/nextjs-auth0/client';
import COUNTRY_NAMES from '@/lib/data/country-names.json';

interface FormData {
  'subscribe-first-name': string;
  'subscribe-last-name': string;
  'subscribe-email': string;
  'subscribe-company': string;
  'subscribe-country': string;
  'subscribe-terms': boolean;
  'machine-tool': boolean;
  'wood-working': boolean;
  'sheet-metal': boolean;
  'plastics-processing': boolean;
  'automation-equipment': boolean;
}

export default function NewsletterForm() {
  const { isNewsletterModalOpen: isOpen } = useAppSelector(
    (state) => state.modals
  );
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const {
    register,
    formState: { errors, isSubmitSuccessful, isSubmitting },
    handleSubmit,
    reset,
  } = useForm<FormData>({
    mode: 'onSubmit',
  });
  async function submit(data: FormData) {
    const machineCategories = [];
    if (data['machine-tool']) {
      machineCategories.push('machine-tool');
    }
    if (data['sheet-metal']) {
      machineCategories.push('sheet-metal');
    }
    if (data['plastics-processing']) {
      machineCategories.push('plastics-processing');
    }
    if (data['automation-equipment']) {
      machineCategories.push('automation-equipment');
    }
    if (data['wood-working']) {
      machineCategories.push('wood-working');
    }
    const submitData = {
      'first-name': data['subscribe-first-name'],
      'last-name': data['subscribe-last-name'],
      email: data['subscribe-email'],
      company: data['subscribe-company'],
      country: data['subscribe-country'],
      'machine-categories': machineCategories,
    };
    console.log('submitData', submitData);
    try {
      await axios.post(
        process.env.NEXT_PUBLIC_CRM_API_URL + 'newsletter-subscribe',
        submitData
      );
      pushDataLayer('buttonClick', {
        label: 'Newsletter SIGN UP Subscription',
      });
    } catch (error) {
      console.log(error);
    }
  }
  const { metadata } = useUserMetadata();
  const { user } = useUser();
  useEffect(() => {
    function handleReset() {
      const country = COUNTRY_NAMES.find(
        (e) => e[1] === (metadata?.country ?? metadata?.companyCountry)
      )?.[1];
      console.log('country', country);
      reset({
        'subscribe-first-name': metadata?.firstName,
        'subscribe-last-name': metadata?.lastName,
        'subscribe-company': metadata?.companyName,
        'subscribe-email': user?.email ?? metadata?.companyEmail,
        'subscribe-country': country,
      });
    }
    // run handleReset after one sec
    const timer = setTimeout(() => handleReset(), 1000);
    return () => clearTimeout(timer);
  }, [metadata, reset, user?.email, isOpen]);

  return (
    <section data-testid='newsletter-modal'>
      <div className='flex w-full justify-center bg-[#263238]'>
        <button
          className='my-16  rounded border-2 border-white px-4 py-4 uppercase tracking-widest text-white'
          onClick={() => {
            dispatch(setIsNewsletterModalOpen(true));
            analyticsOpenNewsletterSub();
          }}
          data-testid='open-newsletter-modal'
        >
          {t('products:FOOTER_BTN_SIGN_UP_NOW')}
        </button>
      </div>
      <style jsx>{`
        label {
          text: white;
        }
      `}</style>
      <Dialog
        open={isOpen}
        onClose={() => dispatch(setIsNewsletterModalOpen(false))}
        className='relative z-50'
      >
        <div className='fixed inset-0 bg-white/70' aria-hidden='true' />

        <div className='fixed inset-0 flex items-center justify-center p-4'>
          <Dialog.Panel
            className={classNames(
              'w-full max-w-md rounded  p-4 transition duration-200',
              isSubmitSuccessful ? 'border-4 bg-slate-100' : 'bg-[#2f3d4f]'
            )}
          >
            <Dialog.Title className='flex items-center justify-between'>
              <h5 className='text-base font-medium uppercase text-white'>
                {t('products:HEADER_TITLE_SUBSCRIBE_NEWSLETTER')}
              </h5>
              <button className=''>
                {/* close icon */}
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  className='h-6 w-6 text-white'
                  fill='none'
                  viewBox='0 0 24 24'
                  stroke='currentColor'
                  onClick={() => dispatch(setIsNewsletterModalOpen(false))}
                >
                  <path
                    strokeLinecap='round'
                    strokeLinejoin='round'
                    strokeWidth={2}
                    d='M6 18L18 6M6 6l12 12'
                  />
                </svg>
              </button>
            </Dialog.Title>
            {isSubmitSuccessful ? (
              <SuccessCheck
                message={t('products:FORM_NEWSLETTER_SUCCESSFUL')}
                subtitle={t(
                  'products:FORM_SUBSCRIPTION_SUCCESSFUL_INFORMATION_1'
                )}
                subtitle2={t(
                  'products:FORM_SUBSCRIPTION_SUCCESSFUL_INFORMATION_2'
                )}
              />
            ) : (
              <form onSubmit={handleSubmit(submit)} className='mt-4 space-y-2'>
                <div className='flex space-x-4'>
                  <div className='block w-1/2'>
                    <Input
                      type='text'
                      name='subscribe-first-name'
                      register={register}
                      errors={errors}
                      placeholder={t('products:FORM_PLACEHOLDER_FIRSTNAME')}
                      validation={{
                        required: true,
                      }}
                    />
                  </div>
                  <div className='block w-1/2'>
                    <Input
                      type='text'
                      name='subscribe-last-name'
                      register={register}
                      errors={errors}
                      placeholder={t('products:FORM_PLACEHOLDER_LASTNAME')}
                      validation={{
                        required: true,
                      }}
                    />
                  </div>
                </div>

                <Input
                  type='text'
                  name='subscribe-company'
                  register={register}
                  errors={errors}
                  placeholder={t('products:FORM_PLACEHOLDER_COMPANY')}
                  validation={{
                    required: true,
                  }}
                />
                <div className='flex space-x-4'>
                  <div className='block w-1/2'>
                    <Input
                      type='email'
                      placeholder='EMAIL'
                      name='subscribe-email'
                      register={register}
                      errors={errors}
                      validation={{
                        required: true,
                      }}
                    />
                  </div>
                  <div className='block w-1/2'>
                    <SelectCountry
                      name='subscribe-country'
                      register={register}
                      errors={errors}
                      returnCountryEnglishName
                    />
                  </div>
                </div>
                <h5 className='text-white'>
                  {t('products:HEADER_FORM_SELECT_CATEGORY')}
                </h5>
                <div className='flex w-full flex-wrap space-y-2'>
                  {getCategories().map((category) => (
                    <div key={category.id} className='flex w-full items-start'>
                      <Input
                        type='checkbox'
                        defaultChecked
                        name={category.slug}
                        register={register}
                        errors={errors}
                        label={t(
                          'products:' + category.name,
                          {},
                          {
                            default: category.name,
                          }
                        )}
                        labelClassNames='!text-white !text-sm break-words max-w-[80%]'
                        containerClassNames='!items-start max-w-full'
                      />
                    </div>
                  ))}
                </div>
                <div className='mt-4'>
                  <span className=' text-gray-400'>
                    {t('products:FORM_ALL_FIELDS_REQUIRED')}
                  </span>
                </div>
                <InputTerms
                  name='subscribe-terms'
                  register={register}
                  errors={errors}
                  labelClassNames='!text-white !text-sm'
                />
                <button
                  type='submit'
                  className='mt-4 w-full rounded bg-secondary-500 py-4 uppercase  text-brand-500'
                  data-testid='submit-newsletter-form'
                  disabled={isSubmitting}
                >
                  {/* TODO: needs to be on i18n seo file? */}
                  {t('seo:FORM_BTN_SUBSCRIBE')}
                </button>
              </form>
            )}
          </Dialog.Panel>
        </div>
      </Dialog>
    </section>
  );
}
