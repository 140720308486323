import { useRouter } from 'next-translate-routes-multi-domain';
import { useEffect, useState } from 'react';
import LoadSpinner from '../visualization/LoadSpinner';
import Layout from '../layout/Layout';
import { isString } from 'lodash-es';

function Loading() {
  const router = useRouter();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const handleStart = (url: string) =>
      url !== router.asPath && setLoading(true);
    const handleComplete = (url: string) =>
      url === router.asPath && setLoading(false);

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  });

  const isProductListPage =
    isString(router.pathname) && router.pathname.includes('/products');

  return loading && !isProductListPage ? ( // fullscreen
    <div className='fixed left-0 top-0 z-50 h-full w-full bg-white'>
      <Layout>
        <LoadSpinner />
      </Layout>
    </div>
  ) : null;
}
export default Loading;
