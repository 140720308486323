import Head from 'next/head';
import Script from 'next/script';

export default function FacebookAnalytics() {
  return (
    <>
      <Head>
        <noscript>
          {/* eslint-disable-next-line @next/next/no-img-element */}
          <img
            height='1'
            width='1'
            alt=''
            src='https://www.facebook.com/tr?id=1477732145639553&ev=PageView&noscript=1'
          />
        </noscript>
      </Head>
      {/* Facebook Pixel Code */}
      <Script
        id='fb-script'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `
          !function(f,b,e,v,n,t,s){
            if(f.fbq)return;n=f.fbq=function()
            {n.callMethod? n.callMethod.apply(n,arguments):n.queue.push(arguments)}
            ;
            if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
            n.queue=[];t=b.createElement(e);t.async=!0;
            t.src=v;s=b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t,s)}(window,document,'script',
            'https://connect.facebook.net/en_US/fbevents.js');
            fbq('init', '1477732145639553');
            fbq('track', 'PageView');
          `,
        }}
      />
    </>
  );
}
