import { Menu, Transition } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';
import Flags from 'country-flag-icons/react/3x2';
import { useRouter } from 'next/router';
import React, { Fragment } from 'react';
import { getDomains } from '@/lib/server/getDomains';
import clsxm from '@/lib/clsxm';
import { translateUrl } from 'next-translate-routes-multi-domain';
import useTranslation from 'next-translate/useTranslation';
import { orderBy } from 'lodash-es';
import { TbWorld } from 'react-icons/tb';
import { pushDataLayer } from '@/lib/utilities/analytics/gtm';

export default function LanguageSelector({
  isNavbarTransparent,
  locales,
}: {
  isNavbarTransparent?: boolean;
  locales?: string[];
}) {
  const { locale, pathname, query } = useRouter();
  const options = useLocaleFlag();
  const currentLanguage = options.find((o) => o.locale === locale);

  return (
    <div className='flex items-center text-center lg:text-right'>
      <Menu as='div' className='relative inline-block text-left'>
        <div>
          <Menu.Button
            className={clsxm(
              'flex w-full items-center justify-center rounded-md border-2 px-2 py-1 text-sm font-medium hover:bg-opacity-30 focus:outline-none  focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 lg:space-x-2 lg:px-4 lg:py-2',
              isNavbarTransparent ? 'bg-white' : ''
            )}
          >
            {currentLanguage?.icon}
            <span className='hidden uppercase lg:block'>
              {currentLanguage?.name}
            </span>
            <ChevronDownIcon
              className='-mr-1 h-5 w-5 text-blue-200 hover:text-blue-100'
              aria-hidden='true'
            />
          </Menu.Button>
        </div>
        <Transition
          as={Fragment}
          enter='transition ease-out duration-100'
          enterFrom='transform opacity-0 scale-95'
          enterTo='transform opacity-100 scale-100'
          leave='transition ease-in duration-75'
          leaveFrom='transform opacity-100 scale-100'
          leaveTo='transform opacity-0 scale-95'
        >
          <Menu.Items className='absolute right-0 z-40 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'>
            <div className='max-h-[85vh] overflow-x-auto px-1 py-1'>
              {options
                .filter((o) => o.locale !== locale)
                .map((o) => {
                  const langDomain = getDomains()?.find(
                    (domain) => domain.defaultLocale == o.locale
                  );
                  const url =
                    langDomain &&
                    'http' in langDomain &&
                    langDomain?.['http'] === true
                      ? 'http://' + langDomain['domain'] + ':3003'
                      : 'https://' + langDomain?.['domain'];
                  return (
                    <Menu.Item key={o.name}>
                      {({ active }) => {
                        let href = '';
                        try {
                          href =
                            url +
                            translateUrl({ pathname, query }, o.locale, {
                              withoutLangPrefix: true,
                              format: 'string',
                            });
                        } catch (e) {
                          console.log('e', e, query);
                          href = '';
                        }

                        return href === '' ||
                          (locales && !locales.includes(o.locale)) ? (
                          <span />
                        ) : (
                          <a href={href}>
                            <button
                              className={`${
                                active
                                  ? 'bg-blue-500 text-white'
                                  : 'text-gray-900'
                              } group flex w-full items-center rounded-md px-2 py-2 text-left text-sm`}
                              onClick={() => {
                                pushDataLayer('buttonClick', {
                                  label:
                                    'Change Language : ' + o.analytics_name,
                                });
                              }}
                            >
                              {o.icon}
                              {o.name}
                            </button>
                          </a>
                        );
                      }}
                    </Menu.Item>
                  );
                })}
            </div>
          </Menu.Items>
        </Transition>
      </Menu>
    </div>
  );
}

export interface LocaleOption {
  name: string;
  analytics_name: string;
  locale: string;
  icon: React.ReactNode;
}

export function useLocaleFlag() {
  const { t } = useTranslation();
  const localeOptions = [
    {
      name: t('products:Germany'),
      analytics_name: 'GERMANY',
      locale: 'de-DE',
      icon: <Flags.DE className='mr-2 h-4' />,
    },
    {
      name: t('products:Spain'),
      analytics_name: 'SPAIN',
      locale: 'es-ES',
      icon: <Flags.ES className='mr-2 h-4' />,
    },
    {
      name: t('products:India'),
      analytics_name: 'INDIA',
      locale: 'en-IN',
      icon: <Flags.IN className='mr-2 h-4' />,
    },
    {
      name: t('products:Italy'),
      analytics_name: 'ITALY',
      locale: 'it-IT',
      icon: <Flags.IT className='mr-2 h-4' />,
    },
    {
      name: t('products:Austria'),
      analytics_name: 'AUSTRIA',
      locale: 'de-AT',
      icon: <Flags.AT className='mr-2 h-4' />,
    },
    {
      name: t('products:Poland'),
      analytics_name: 'POLAND',
      locale: 'pl-PL',
      icon: <Flags.PL className='mr-2 h-4' />,
    },
    {
      name: t('products:Sweden'),
      analytics_name: 'SWEDEN',
      locale: 'sv-SE',
      icon: <Flags.SE className='mr-2 h-4' />,
    },
    {
      name: t('products:United Kingdom'),
      analytics_name: 'UNITED KINGDOM',
      locale: 'en-GB',
      icon: <Flags.GB className='mr-2 h-4' />,
    },
    {
      name: t('products:Finland'),
      analytics_name: 'FINLAND',
      locale: 'fi-FI',
      icon: <Flags.FI className='mr-2 h-4' />,
    },
    {
      name: t('products:Netherlands'),
      analytics_name: 'NETHERLANDS',
      locale: 'nl-NL',
      icon: <Flags.NL className='mr-2 h-4' />,
    },
    {
      name: t('products:Croatia'),
      analytics_name: 'CROATIA',
      locale: 'hr-HR',
      icon: <Flags.HR className='mr-2 h-4' />,
    },
    {
      name: t('products:Turkey'),
      analytics_name: 'TURKEY',
      locale: 'tr-TR',
      icon: <Flags.TR className='mr-2 h-4' />,
    },
    {
      name: t('products:France'),
      analytics_name: 'FRANCE',
      locale: 'fr-FR',
      icon: <Flags.FR className='mr-2 h-4' />,
    },
    {
      name: t('products:Czech Republic'),
      analytics_name: 'CZECH REPUBLIC',
      locale: 'cs-CZ',
      icon: <Flags.CZ className='mr-2 h-4' />,
    },
    {
      name: t('products:Hungary'),
      analytics_name: 'HUNGARY',
      locale: 'hu-HU',
      icon: <Flags.HU className='mr-2 h-4' />,
    },
    {
      name: t('products:Latvia'),
      analytics_name: 'LATVIA',
      locale: 'lv-LV',
      icon: <Flags.LV className='mr-2 h-4' />,
    },
    {
      name: t('products:Lithuania'),
      analytics_name: 'LITHUANIA',
      locale: 'lt-LT',
      icon: <Flags.LI className='mr-2 h-4' />,
    },
    {
      name: t('products:Estonia'),
      analytics_name: 'ESTONIA',
      locale: 'et-EE',
      icon: <Flags.EE className='mr-2 h-4' />,
    },
    {
      name: t('products:Portugal'),
      analytics_name: 'PORTUGAL',
      locale: 'pt-PT',
      icon: <Flags.PT className='mr-2 h-4' />,
    },
    {
      name: t('products:Ireland'),
      analytics_name: 'IRELAND',
      locale: 'en-IE',
      icon: <Flags.IE className='mr-2 h-4' />,
    },
    {
      name: t('products:Mexico'),
      analytics_name: 'MEXICO',
      locale: 'es-MX',
      icon: <Flags.MX className='mr-2 h-4' />,
    },
    {
      name: t('products:Russia'),
      analytics_name: 'RUSSIA',
      locale: 'ru-RU',
      icon: <Flags.RU className='mr-2 h-4' />,
    },
    {
      name: 'Ukraine',
      analytics_name: 'UKRAINE',
      locale: 'uk-UA',
      icon: <Flags.UA className='mr-2 h-4' />,
    },
  ];
  return [
    {
      name: t('products:COUNTRY_SELECTION_POPUP_COUNTRY_GLOBAL'),
      locale: 'en-US',
      icon: <TbWorld className='mr-2 h-6 w-6' />,
      analytics_name: 'GLOBAL',
    },
    ...orderBy(localeOptions, 'name', 'asc'),
  ];
}
