import { IProductAccountManager } from '@/lib/types/api';

export const DEFAULT_HEADERS = {
  'Content-Type': 'application/json',
};

export const DEFAULT_PAGE_SIZE = 36;

export const DEFAULT_ACCOUNT_MANAGER = {
  name: 'Borja Garcia',
  email: 'b.garcia@gindumac.com',
  profile_picture:
    'https://s3.eu-central-1.amazonaws.com/gindumac/staff/profile_pictures/borja-garcia.png',
  phone: '+34 679 019 350',
  locales: [
    'en-GB',
    'en-IE',
    'en-IN',
    'et-EE',
    'fi-FI',
    'hr-HR',
    'hu-HU',
    'it-IT',
    'lt-LT',
    'lv-LV',
    'nl-NL',
    'pl-PL',
    'sv-SE',
    'tr-TR',
  ],
  position: 'Account Manager',
} as IProductAccountManager;
