function isProduction() {
  return process.env.NEXT_PUBLIC_APP_ENV == 'production';
}

function isStaging() {
  return process.env.NEXT_PUBLIC_APP_ENV == 'staging';
}

function isDevelopment() {
  return process.env.NODE_ENV == 'development';
}

function isTesting() {
  return process.env.NEXT_PUBLIC_APP_ENV == 'test';
}

exports.isProduction = isProduction;
exports.isStaging = isStaging;
exports.isDevelopment = isDevelopment;
exports.isTesting = isTesting;
