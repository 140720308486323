import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Languages } from '@/lib/types/i18n';
import { AppState } from '@/redux/store';
import {
  ValueAndLabel,
  ValueLabelSlug,
} from '@/components/ui/Forms/Autocomplete/Autocomplete';

export type IOrderby =
  | 'recent_added_first'
  | 'price_lowest_first'
  | 'price_highest_first'
  | 'year_newest_first'
  | 'year_oldest_first'
  | 'production_hours_highest_first'
  | 'production_hours_lowest_first';

export type Iorder = 'desc' | 'asc';
export type ILocale = Languages | undefined;

export type ProductFilterStateKeys = keyof ProductFilterState;

export interface TechnicalFilters {
  // machine tool
  min_xaxis_movement?: number;
  max_xaxis_movement?: number;
  min_tablesize?: number;
  max_tablesize?: number;
  // sheet metal
  min_xaxis_sheetmetal?: number;
  max_xaxis_sheetmetal?: number;
  min_force_sheetmetal?: number;
  max_force_sheetmetal?: number;
  min_thickness_sheetmetal?: number;
  max_thickness_sheetmetal?: number;
  min_laser_sheetmetal?: number;
  max_laser_sheetmetal?: number;
  // plastics processing
  min_clamping_force?: number;
  max_clamping_force?: number;
  min_screw_diameter?: number;
  max_screw_diameter?: number;
  // automation equipment
  min_maximum_reach?: number;
  max_maximum_reach?: number;
  min_maximum_payload?: number;
  max_maximum_payload?: number;
}

export interface ProductFilterState extends TechnicalFilters {
  page: number;
  min_price?: number;
  max_price?: number;
  machine_category?: string;
  brand?: ValueAndLabel[];
  model?: ValueAndLabel[];
  machine_type?: ValueLabelSlug[];
  localization_continent?: string[];
  localization_country?: ValueAndLabel[];
  application_type?: ValueAndLabel[];
  search?: string;
  order_by?: IOrderby;
  order?: Iorder;
  year_range?: string;
  control_unit_brand?: string[];
}

export const initialState: ProductFilterState = {
  page: 1,
  order_by: 'recent_added_first',
  order: 'desc',
};

export const productFilterSlice = createSlice({
  name: 'productFilter',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<number>) => {
      if (state.page !== action.payload) {
        state.page = action.payload;
      }
    },
    setMinPrice: (state, action: PayloadAction<number | undefined>) => {
      state.min_price = action.payload;
    },
    setMaxPrice: (state, action: PayloadAction<number | undefined>) => {
      state.max_price = action.payload;
    },
    setLocalizationContinent: (
      state,
      action: PayloadAction<string | undefined>
    ) => {
      state.localization_continent = action.payload
        ? [action.payload]
        : undefined;
    },
    setLocalizationCountry: (state, action: PayloadAction<ValueAndLabel[]>) => {
      state.localization_country = action.payload;
    },
    setApplicationType: (state, action: PayloadAction<ValueAndLabel[]>) => {
      state.application_type = action.payload;
    },
    setControlUnitBrand: (state, action: PayloadAction<string[]>) => {
      state.control_unit_brand = action.payload;
    },
    setBrands: (state, action: PayloadAction<ValueLabelSlug[]>) => {
      state.brand = action.payload;
    },
    setModels: (state, action: PayloadAction<ValueAndLabel[]>) => {
      state.model = action.payload;
    },
    setMachineType: (state, action: PayloadAction<ValueLabelSlug[]>) => {
      state.machine_type = action.payload;
    },
    setYearRange: (state, action: PayloadAction<string>) => {
      state.year_range = action.payload;
    },
    incrementPage: (state) => {
      state.page += 1;
    },
    setSearch: (state, action: PayloadAction<string | undefined>) => {
      state.search = action.payload;
    },
    decrementPage: (state) => {
      state.page -= 1;
    },
    setOrderby: (state, action: PayloadAction<IOrderby>) => {
      state.order_by = action.payload;
    },
    setMinXAxisMovement: (state, action: PayloadAction<number | undefined>) => {
      state.min_xaxis_movement = action.payload;
    },
    setMaxXAxisMovement: (state, action: PayloadAction<number | undefined>) => {
      state.max_xaxis_movement = action.payload;
    },
    setMinTableSize: (state, action: PayloadAction<number | undefined>) => {
      state.min_tablesize = action.payload;
    },
    setMaxTableSize: (state, action: PayloadAction<number | undefined>) => {
      state.max_tablesize = action.payload;
    },
    setMinXaxisSheetmetal: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.min_xaxis_sheetmetal = action.payload;
    },
    setMaxXaxisSheetmetal: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.max_xaxis_sheetmetal = action.payload;
    },
    setMinForceSheetMetal: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.min_force_sheetmetal = action.payload;
    },
    setMaxForceSheetMetal: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.max_force_sheetmetal = action.payload;
    },
    setMinThicknessSheetMetal: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.min_thickness_sheetmetal = action.payload;
    },
    setMaxThicknessSheetMetal: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.max_thickness_sheetmetal = action.payload;
    },
    setMinLaserSheetMetal: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.min_laser_sheetmetal = action.payload;
    },
    setMaxLaserSheetMetal: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.max_laser_sheetmetal = action.payload;
    },
    setMinClampingForce: (state, action: PayloadAction<number | undefined>) => {
      state.min_clamping_force = action.payload;
    },
    setMaxClampingForce: (state, action: PayloadAction<number | undefined>) => {
      state.max_clamping_force = action.payload;
    },
    setMinScrewDiameter: (state, action: PayloadAction<number | undefined>) => {
      state.min_screw_diameter = action.payload;
    },
    setMaxScrewDiameter: (state, action: PayloadAction<number | undefined>) => {
      state.max_screw_diameter = action.payload;
    },
    setMinMaximumReach: (state, action: PayloadAction<number | undefined>) => {
      state.min_maximum_reach = action.payload;
    },
    setMaxMaximumReach: (state, action: PayloadAction<number | undefined>) => {
      state.max_maximum_reach = action.payload;
    },
    setMinMaximumPayload: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.min_maximum_payload = action.payload;
    },
    setMaxMaximumPayload: (
      state,
      action: PayloadAction<number | undefined>
    ) => {
      state.max_maximum_payload = action.payload;
    },
    // TODO: refactor rename to set filters ?
    loadFiltersFromUrlParams: (
      state,
      action: PayloadAction<ProductFilterState>
    ) => {
      const result = { ...initialState, ...action.payload };
      return result;
    },
  },
});

export const {
  setPage,
  incrementPage,
  decrementPage,
  setMinPrice,
  setMaxPrice,
  setLocalizationContinent,
  setLocalizationCountry,
  setApplicationType,
  setControlUnitBrand,
  setBrands,
  setModels,
  setMachineType,
  setSearch,
  setYearRange,
  setOrderby,
  loadFiltersFromUrlParams,
  setMinXAxisMovement,
  setMaxXAxisMovement,
  setMinTableSize,
  setMaxTableSize,
  setMinXaxisSheetmetal,
  setMaxXaxisSheetmetal,
  setMinForceSheetMetal,
  setMaxForceSheetMetal,
  setMinThicknessSheetMetal,
  setMaxThicknessSheetMetal,
  setMinLaserSheetMetal,
  setMaxLaserSheetMetal,
  setMinClampingForce,
  setMaxClampingForce,
  setMinScrewDiameter,
  setMaxScrewDiameter,
  setMinMaximumReach,
  setMaxMaximumReach,
  setMinMaximumPayload,
  setMaxMaximumPayload,
} = productFilterSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectPage = (state: AppState) => state.productFilter.page;

export default productFilterSlice.reducer;
