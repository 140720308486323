import { useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { GTM_ID, pageview, pushDataLayer } from '@/lib/utilities/analytics/gtm';
import store from 'store2';
export default function GoogleAnalytics() {
  const router = useRouter();

  useEffect(() => {
    router.events.on('routeChangeComplete', logPageView);
    return () => {
      router.events.off('routeChangeComplete', logPageView);
    };
  }, [router.events]);

  useEffect(() => {
    const cookieConsent = store.get('cookieConsent');
    if (cookieConsent === 'yes' || cookieConsent === 'no') {
      updateGTMConsent(cookieConsent === 'yes');
    }
  }, []);

  return (
    <>
      {/* Google Tag Manager */}
      <Script
        id='gtm-script'
        strategy='afterInteractive'
        dangerouslySetInnerHTML={{
          __html: `

  (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':

  new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],

  j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=

  'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);

  })(window,document,'script','dataLayer','${GTM_ID}');

  function gtag(){window.dataLayer.push(arguments);}

`,
        }}
      />
    </>
  );
}

function logPageView(url: string, { shallow }: { shallow: boolean }) {
  const DISABLE_ANALYTICS_FOR_URL_QUERY_UPDATE = !shallow;
  if (
    process.env.NEXT_PUBLIC_ENABLE_TRACKING &&
    process.env.NEXT_PUBLIC_ENABLE_TRACKING != 'false' &&
    DISABLE_ANALYTICS_FOR_URL_QUERY_UPDATE
  ) {
    try {
      pageview(window.location.pathname);
    } catch (e) {
      console.error('error logging page view', e);
    }
  }
}

export function analyticsButtonClick(label: string) {
  pushDataLayer('buttonClick', {
    label,
  });
}

export function gaClickVDWF() {
  pushDataLayer('buttonClick', {
    label: 'VDWF logo',
  });
}

export const updateGTMConsent = (consent: boolean | 'yes' | 'no') => {
  consent = consent == 'yes' || consent == true;
  if (window.gtag) {
    window.gtag('consent', 'update', {
      ad_storage: consent ? 'granted' : 'denied',
      analytics_storage: consent ? 'granted' : 'denied',
      ad_user_data: consent ? 'granted' : 'denied',
      ad_personalization: consent ? 'granted' : 'denied',
    });
  }
};
