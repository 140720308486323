const LOCALES_FORMAT = {
  'en-US': {
    thousand: ',',
    decimal: '.',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'MMM D',
  },
  'en-IE': {
    thousand: ',',
    decimal: '.',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'MMM D',
  },
  'de-DE': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'MMM D',
  },
  'es-ES': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'en-IN': {
    thousand: ',',
    decimal: '.',
    currency: 'Rs.',
    currency_ISO: 'INR',
    signFormat: '%s %v',
    dateCalendar: 'MMM D',
  },
  'it-IT': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'de-AT': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'MMM D',
  },
  'pl-PL': {
    thousand: ' ',
    decimal: ',',
    currency: 'zł',
    currency_ISO: 'PLN',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'sv-SE': {
    thousand: ' ',
    decimal: ',',
    currency: 'SEK',
    currency_ISO: 'SEK',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'en-GB': {
    thousand: ',',
    decimal: '.',
    currency: '£',
    currency_ISO: 'GBP',
    signFormat: '%s %v',
    dateCalendar: 'MMM D',
  },
  'fi-FI': {
    thousand: ' ',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMMM',
  },
  'nl-NL': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'hr-HR': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'tr-TR': {
    thousand: ',',
    decimal: '.',
    currency: 'TL',
    currency_ISO: 'TRY',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'cs-CZ': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'hu-HU': {
    thousand: ',',
    decimal: '.',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'lv-LV': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'lt-LT': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'et-EE': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'pt-PT': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'fr-FR': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'es-MX': {
    thousand: ',',
    decimal: '.',
    currency: 'MXN',
    currency_ISO: 'MXN',
    signFormat: '%s %v',
    dateCalendar: 'D MMM',
  },
  'ru-RU': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
  'uk-UA': {
    thousand: '.',
    decimal: ',',
    currency: '€',
    currency_ISO: 'EUR',
    signFormat: '%v %s',
    dateCalendar: 'D MMM',
  },
};

module.exports = {
  LOCALES_FORMAT,
};
